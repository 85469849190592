import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import Table from "../../core/pagination/datatable";
import { error_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";
import Select from 'react-select';

const SwingSeat = () => {
    const [partyId, setPartyId] = useState(null);
    const [selectedYears, setSelectedYears] = useState([]);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentYear, setCurrentYear] = useState("");
    const [previousYears, setPreviousYears] = useState([]);
    const getAllParties = useFetch("getAllParties");

    const years = [
        { value: '2010', label: '2010' },
        { value: '2011', label: '2011' },
        { value: '2012', label: '2012' },
        { value: '2013', label: '2013' },
        { value: '2014', label: '2014' },
        { value: '2015', label: '2015' },
        { value: '2016', label: '2016' },
        { value: '2017', label: '2017' },
        { value: '2018', label: '2018' },
        { value: '2019', label: '2019' },
        { value: '2020', label: '2020' },
        { value: '2021', label: '2021' },
        { value: '2022', label: '2022' },
        { value: '2023', label: '2023' },
    ];
    

    const submit = async () => {
        try {
            setLoading(true);
            let response = await PostApi("swingSeats", { partyId, years: selectedYears.map(year => year.value) });
            console.log(response);
            if (response.data && response.data.status === "1") {
                const data = response.data.data.data;

                // Set previous years from response
                setPreviousYears(Object.keys(data[0].previousWinners || {}));
                
                const newData = data.map(item => ({
                    constituencyName: item.constituencyName,
                    regionName: item.regionName,
                    currentYearWinner: item.currentYearWinner,
                    ...item.previousWinners, // Spread previous winners
                }));
                
                setList(newData);
                setCurrentYear(response.data.data.currentYear);
                setLoading(false);
            } else {
                setLoading(false);
                error_toaster(response.data.message);
                console.error("Error fetching swing seat summary:", response.data.message);
            }
        } catch (error) {
            setLoading(false);
            error_toaster("Something went wrong");
            console.error("Error in submit function:", error);
        }
    };

    // Create columns dynamically based on previousYears
    const columns = [
        {
            title: "Constituency Name",
            dataIndex: "constituencyName",
            sorter: (a, b) => a.constituencyName.localeCompare(b.constituencyName),
        },
        {
            title: "Region Name",
            dataIndex: "regionName",
            sorter: (a, b) => a.regionName.localeCompare(b.regionName),
        },
        ...previousYears.map(year => ({
            title: `${year} Winner`,
            dataIndex: year, // Match the key to the year
        })),
        {
            title: `${currentYear} Year Winner`,
            dataIndex: "currentYearWinner",
        },
    ];

    return (
        <div className="page-wrapper">
            {
                loading ? <Loader /> : <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Swing Seat Summary</h4>
                                <h6>Summary</h6>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Select Party</label>
                                        <select
                                            name="party"
                                            value={partyId}
                                            onChange={(e) => setPartyId(e.target.value)}
                                            className="form-control"
                                        >
                                            <option value="">Select Party</option>
                                            {getAllParties?.apiData?.data?.parties?.map((party) => (
                                                <option key={party.id} value={party.id}>
                                                    {party.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Select Years</label>
                                        <Select
                                            options={years}
                                            isMulti
                                            onChange={setSelectedYears}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button onClick={submit} className="btn btn-primary mt-3">
                                Submit
                            </button>
                        </div>
                    </div>

                    {/* Table Section */}
                    <div className="card mb-4 shadow my-3">
                        <div className="card-body">
                            <h5 className="card-title">Summary</h5>
                            <Table
                                columns={columns}
                                dataSource={list}
                                pagination={{ pageSize: 5 }}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default SwingSeat;
