import React, { useEffect, useState } from "react";
import { File, User, UserCheck } from "feather-icons-react/build/IconComponents";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import Chart from "react-apexcharts"; // Import the Chart component
import Loader from "../../Utils/Loader";
import { error_toaster } from "../../Utils/Toaster";

const AgentDashboard = () => {
    const [userId, setUserId] = useState("");
    const [partyId, setPartyId] = useState("");
    const [type, setType] = useState("");
    const [loading, setLoading] = useState(false);
    const [candidates, setCandidates] = useState([]);
    const [constId, setConstId] = useState("");
    const [chartOptions, setChartOptions] = useState({
        chart: {
            id: "basic-bar"
        },
        xaxis: {
            categories: []
        },
        yaxis: {
            max: 300 // Initial max value
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '20%', // Set a fixed bar width percentage
                barHeight: '50%', // You can use this if you're doing a horizontal bar chart, otherwise it can be omitted
                borderRadius: 5, // Adds some rounding to the bars to make them look nicer
            }
        },
        colors: [] // Set colors based on type
    });

    const [chartSeries, setChartSeries] = useState([]);

    // Get user ID and party ID from local storage
    useEffect(() => {
        setUserId(localStorage.getItem("userId"));
        setPartyId(localStorage.getItem("partyId"));
    }, []);

    // Fetch dashboard data
    const { apiData } = useFetch(`agentDashboard/${userId}`);

    // Handle polling station selection
    const selectedPollingStation = async (id) => {
        if (type === "") {
            error_toaster("Please select candidate type!");
            return false;
        }
        setLoading(true);
        setConstId(id);
        const response = await PostApi("pollingStationGraphData", { id: id, partyId, type: type });
        console.log("Polling Station Graph Data: ", JSON.stringify(response));

        // Handle candidates data from the API response
        const formattedCandidates = response?.data?.data?.formattedCandidates || [];
        setCandidates(formattedCandidates);
        setLoading(false);
    };

    // Update chart based on candidates data
    useEffect(() => {
        if (candidates.length === 0) return; // Avoid processing if no candidates data

        const categories = []; // To store candidate names for the x-axis
        const votes = []; // To store votes for each candidate
        let maxVote = 300; // Default max value for y-axis

        // Iterate over the candidates data to extract candidate names and their votes
        candidates.forEach(candidate => {
            const candidateName = candidate?.name || "Unknown Candidate"; // Candidate name
            categories.push(candidateName); // Add candidate names to x-axis categories

            // Get votes, default to 0 if not available
            const vote = candidate?.data?.[0]?.y || 0;
            votes.push(vote); // Push the votes into respective arrays

            // Track the maximum vote value for dynamic y-axis scaling
            if (vote > maxVote) {
                maxVote = vote;
            }
        });

        // Update chart options with the processed data and dynamic y-axis max
        setChartOptions(prevOptions => ({
            ...prevOptions,
            xaxis: { categories }, // Set x-axis categories (candidate names)
            yaxis: { ...prevOptions.yaxis, max: maxVote + 50 }, // Dynamically set y-axis max with padding
            colors: type === "Presidential" ? ["#EA5455"] : ["#28C76F"], // Set specific colors for each type
        }));

        // Update the chart series with the new data
        setChartSeries([{
            name: type, // Name the series based on type
            data: votes,
        }]);

    }, [candidates, type]); // Depend on candidates and type state to update chart

    // Reset chart data when type changes
    useEffect(() => {
        setCandidates([]); // Reset candidates
        setChartSeries([]); // Reset chart series
        setChartOptions(prevOptions => ({
            ...prevOptions,
            xaxis: { categories: [] }, // Reset x-axis categories
            yaxis: { ...prevOptions.yaxis, max: 300 }, // Reset y-axis max
            colors: [], // Reset colors
        }));
    }, [type]); // Run when the type changes

    return (
        <div>
            <div className="page-wrapper">
                {loading ? <Loader /> : <div className="content">
                    <div className="row">
                        <div className="col-xl-3 col-sm-6 col-12 d-flex">
                            <div className="dash-count">
                                <div className="dash-counts">
                                    <h4>{apiData?.data?.constituencyCount || "0"}</h4>
                                    <h5>No. of Constituencies</h5>
                                </div>
                                <div className="dash-imgs">
                                    <User />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12 d-flex">
                            <div className="dash-count das1">
                                <div className="dash-counts">
                                    <h4>{apiData?.data?.pollingstationCount || "0"}</h4>
                                    <h5>No. of Polling Stations</h5>
                                </div>
                                <div className="dash-imgs">
                                    <UserCheck />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12 d-flex">
                            <div className="dash-count das2">
                                <div className="dash-counts">
                                    <h4>{apiData?.data?.presidentialCandidates || "0"}</h4>
                                    <h5>Presidential Candidates</h5>
                                </div>
                                <div className="dash-imgs">
                                    <ImageWithBasePath
                                        src="assets/img/icons/file-text-icon-01.svg"
                                        className="img-fluid"
                                        alt="icon"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12 d-flex">
                            <div className="dash-count das3">
                                <div className="dash-counts">
                                    <h4>{apiData?.data?.parlimentaryCandidates || "0"}</h4>
                                    <h5>Parliamentary Candidates</h5>
                                </div>
                                <div className="dash-imgs">
                                    <File />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-sm-12 col-12 d-flex">
                            <div className="card flex-fill">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5 className="card-title mb-0">Polling Station Report</h5>
                                    <div className="graph-sets">
                                        <div className="dropdown dropdown-wraper" style={{ margin: "0px 5px" }}>
                                            <select
                                                defaultValue={type}
                                                onChange={(e) => setType(e.target.value)}
                                                className="form-control bg-warning text-white"
                                            >
                                                <option value="">Select Candidate Type</option>
                                                <option value="Presidential">Presidential</option>
                                                <option value="Parliamentary">Parliamentary</option>
                                            </select>
                                        </div>
                                        <div className="dropdown dropdown-wraper">
                                            <select
                                                defaultValue={constId}
                                                onChange={(e) => selectedPollingStation(e.target.value)}
                                                className="form-control bg-info text-white"
                                            >
                                                <option>Select Polling Stations</option>
                                                {apiData?.data?.pollingstations?.map((cons) => (
                                                    <option
                                                        key={cons?.pollingstation?.id}
                                                        value={cons?.pollingstation?.id}
                                                    >
                                                        {cons?.pollingstation?.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Chart
                                        options={chartOptions} // Chart configuration
                                        series={chartSeries} // Data for the chart
                                        type="bar"
                                        height={320}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default AgentDashboard;
