import React, { useEffect, useState, useMemo, useRef } from "react";
import { Search, Upload } from "react-feather";
import { Link } from "react-router-dom";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";
import { Trash2 } from "feather-icons-react/build/IconComponents";

const Record = () => {
  const [recordData, setRecordData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // New state for filtered data
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const [candidateTypeFilter, setCandidateTypeFilter] = useState(""); // New state for candidate type filter

  // State for form fields
  const [year, setYear] = useState("");
  const [type, setType] = useState(null);
  const [voters, setVoters] = useState("");
  const [candidate, setCandidate] = useState("");
  const [constituency, setConstituency] = useState("");
  const [percentage, setPercentage] = useState("");
  const [district, setDistrict] = useState("");
  const [region, setRegion] = useState("");
  const [party, setParty] = useState("");
  const fileInputRef = useRef(null); // Ref for file input

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setEdit(false);
    setShow(true);
    resetForm();
  };

  const resetForm = () => {
    setYear("");
    setVoters("");
    setCandidate("");
    setConstituency("");
    setDistrict("");
    setRegion("");
    setParty("");
    setType("");
  };

  const { apiData, reFetch } = useFetch(`getAllRecords`); // Replace with actual API endpoint
  const getAllConsistuencies = useFetch(`getAllConsistuencies`); // Replace with actual API endpoint
  const parties = useFetch(`allPartiesForRecords`); // Replace with actual API endpoint

  const constChange = async (id) => {
    setConstituency(id);
  };

  const transformedData = useMemo(() => {
    if (apiData?.data?.records) {
      return apiData?.data?.records.map((record) => ({
        key: record?.id,
        year: record?.year,
        voters: record?.votes,
        candidate: record?.candidate,
        candidatetype: record?.candidatetype?.name,
        candidatetypeId: record?.candidatetypeId,
        percentage: `${record?.percentage}%`,
        constituency: record?.constituency?.name,
        areaName: record?.constituency?.areaName,
        areaCode: record?.constituency?.areaCode,
        district: record?.district?.name,
        region: record?.region?.name,
        party: record?.party?.name,
        regionId: record?.region?.id,
        regionCode: record?.region?.code,
        districtId: record?.district?.id,
        districtCode: record?.district?.code,
        constituencyId: record?.constituency?.id,
        partyId: record?.party?.id,
      }));
    }
    return [];
  }, [apiData]);

  useEffect(() => {
    setRecordData(transformedData);
    setFilteredData(transformedData); // Initialize filteredData with the full set of records
  }, [transformedData]);

  // Handle search query change
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    filterData(query, candidateTypeFilter);
  };

  // Handle candidate type change
  const handleCandidateTypeChange = (e) => {
    const selectedType = e.target.value;
    setCandidateTypeFilter(selectedType);
    filterData(searchQuery, selectedType);
  };
  console.log(candidateTypeFilter)

  // Function to filter data based on search query and candidate type
  const filterData = (query, type) => {
    const filteredRecords = recordData.filter((record) => {
      const matchesQuery = record.candidate.toLowerCase().includes(query);
      const matchesType = type ? record.candidatetype === type : true;
      return matchesQuery && matchesType;
    });
    setFilteredData(filteredRecords);
  };

  const addRecord = async (e) => {
    e.preventDefault();
    if (!year || !voters || !candidate || !constituency || !percentage || !party) {
      error_toaster("All fields are required!");
      return false;
    }

    setLoading(true);
    const response = await PostApi("addRecord", {
      year,
      voters,
      candidate,
      constituencyId: constituency,
      district,
      region,
      type,
      party,
      percentage,
    });
    console.log(response);
    if (response.data.status === "1") {
      reFetch();
      setLoading(false);
      setShow(false);
      success_toaster(response.data.message);
    } else {
      error_toaster(response.data.message);
      setLoading(false);
    }
  };

  const deleteRecord = async (obj) => {
    setLoading(true);
    const response = await PostApi("deleteRecord", {
      id: obj.key,
    });
    if (response.data.status === "1") {
      reFetch();
      setLoading(false);
      setShow(false);
      success_toaster(response.data.message);
    } else {
      error_toaster(response.data.message);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Year",
      dataIndex: "year",
      sorter: (a, b) => a.year - b.year,
    },
    {
      title: "Party",
      dataIndex: "party",
      sorter: (a, b) => a.party.length - b.party.length,
    },
    {
      title: "No. of Votes",
      dataIndex: "voters",
      sorter: (a, b) => a.voters - b.voters,
    },
    {
      title: "% of Votes",
      dataIndex: "percentage",
      sorter: (a, b) => a.voters - b.voters,
    },
    {
      title: "Candidate",
      dataIndex: "candidate",
      sorter: (a, b) => a.candidate.length - b.candidate.length,
    },
    {
      title: "Candidate Type",
      dataIndex: "candidatetype",
      sorter: (a, b) => a.candidate.length - b.candidate.length,
    },
    {
      title: "Constituency",
      dataIndex: "constituency",
      sorter: (a, b) => a.constituency.length - b.constituency.length,
    },
    // {
    //   title: "Area Name",
    //   dataIndex: "areaName",
    //   sorter: (a, b) => a.constituency.length - b.constituency.length,
    // },
    // {
    //   title: "Area Code",
    //   dataIndex: "areaCode",
    //   sorter: (a, b) => a.constituency.length - b.constituency.length,
    // },
    {
      title: "District",
      dataIndex: "district",
      sorter: (a, b) => a.district.length - b.district.length,
    },
    {
      title: "D-code",
      dataIndex: "districtCode",
      sorter: (a, b) => a.district.length - b.district.length,
    },
    {
      title: "Region",
      dataIndex: "region",
      sorter: (a, b) => a.region.length - b.region.length,
    },
    {
      title: "R-Code",
      dataIndex: "regionCode",
      sorter: (a, b) => a.region.length - b.region.length,
    },
  
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link className="me-2 p-2">
              <Trash2 onClick={() => deleteRecord(record)} className="feather-trash-2" />
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const handleUploadClick = () => {
    fileInputRef.current.click(); // Trigger file input click
  };
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      error_toaster("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    setLoading(true);
    const response = await PostApi("addRecordsFromExcel", formData, true);
    console.log(response);
    if (response.data.status === "1") {
      reFetch();
      setLoading(false);
      success_toaster(response.data.message);
    } else {
      error_toaster(response.data.message);
      setLoading(false);
    }
  };

  return (
    <div className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Record Management</h4>
                <h6>Manage Records</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={<Tooltip id="upload-tooltip">Upload</Tooltip>}>
                  <button className="btn btn-link" onClick={handleUploadClick}>
                    <Upload />
                  </button>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <button onClick={handleShow} className="btn btn-added">
                <PlusCircle className="me-2" />
                Add New
              </button>
            </div>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search by Candidate Name"
                      className="form-control form-control-sm formsearch bg-warning text-white"
                      value={searchQuery}
                      onChange={handleSearchChange} // New onChange event handler
                    />
                    <Link to="#" className="btn btn-searchset">
                      <Search className="feather-search" />
                    </Link>
                  </div>
                  <div className="candidate-type-filter">
                  <select
                    value={candidateTypeFilter}
                    onChange={handleCandidateTypeChange}
                    className="form-control bg-info text-white"
                  >
                    <option value="">All Candidate Types</option>
                    <option value="Parliamentary">Parliamentary</option>
                    <option value="Presidential">Presidential</option>
                  </select>
                </div>
                </div>
                
              </div>
              <div className="table-responsive">
                <Table
                  className="table table-hover table-center mb-0 datatable"
                  columns={columns}
                  dataSource={filteredData} // Use filteredData instead of recordData
                  rowKey={(record) => record.key}
                />
              </div>
            </div>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".xlsx, .xls"
            onChange={handleFileChange}
          />
          <Modal show={show} size="lg" onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{edit ? "Update Record" : "Add New Record"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="p-2">
                {/* Year Select */}
                <div className="form-group">
                  <label>Year</label>
                  <select value={year} onChange={(e) => setYear(e.target.value)} className="form-control">
                    <option value="">Select Year</option>
                    {Array.from({ length: 21 }, (_, i) => (
                      <option key={i} value={2000 + i}>
                        {2000 + i}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Voters Field */}
                <div className="form-group">
                  <label>No. of Votes</label>
                  <input
                    value={voters}
                    onChange={(e) => setVoters(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Enter Votes"
                  />
                </div>
                <div className="form-group">
                  <label>% of Votes</label>
                  <input
                    value={percentage}
                    onChange={(e) => setPercentage(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Enter Votes"
                  />
                </div>

                {/* Candidate Field */}
                <div className="form-group">
                  <label>Candidate</label>
                  <input
                    value={candidate}
                    onChange={(e) => setCandidate(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Enter Candidate"
                  />
                </div>
                <div className="form-group">
                  <label>Candidate Type</label>
                  <select
                    name="candidateType"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    className="form-control"
                  >
                    <option value="">Select Candidate Type</option>
                    <option value="Parliamentary">Parliamentary</option>
                    <option value="Presidential">Presidential</option>
                  </select>
                </div>

                {/* Party Select */}
                <div className="form-group">
                  <label>Party</label>
                  <select value={party} onChange={(e) => setParty(e.target.value)} className="form-control">
                    <option>Select Party</option>
                    {parties?.apiData?.data?.parties?.map((party) => (
                      <option key={party.id} value={party.id}>
                        {party.name}
                      </option>
                    ))}
                  </select>
                </div>
                {/* Constituency Select */}
                <div className="form-group">
                  <label>Constituency</label>
                  <select
                    value={constituency}
                    onChange={(e) => constChange(e.target.value)}
                    className="form-control"
                  >
                    <option>Select Constituency</option>
                    {getAllConsistuencies?.apiData?.data?.consistuencies?.map((constituency) => (
                      <option key={constituency.id} value={constituency.id}>
                        {constituency.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Loading and Submit Buttons */}
                {loading ? (
                  <div className="d-flex justify-content-center align-content-center">
                    <Loader />
                  </div>
                ) : (
                  <div className="d-flex justify-content-center gap-3 mt-3">
                    <button type="button" className="btn btn-secondary" onClick={handleClose}>
                      Close
                    </button>

                    <button onClick={addRecord} type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                )}
              </form>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Record;
