import React, { useEffect, useState, useMemo } from "react";
import { Search } from "react-feather";
import { Link } from "react-router-dom";
import Table from "../../core/pagination/datatable";
import useFetch from "../../ApiClient/GetApi";

const PastRecord = () => {
    const [recordData, setRecordData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [candidateTypeFilter, setCandidateTypeFilter] = useState("");
    const [constituencyFilter, setConstituencyFilter] = useState("");
    const [yearFilter, setYearFilter] = useState(""); // New state for year filter

    const { apiData } = useFetch(`getAllRecords`);
    const getAllConsistuencies = useFetch(`getAllConsistuencies`);

    const constituenciesList = getAllConsistuencies?.apiData?.data?.consistuencies || [];

    const transformedData = useMemo(() => {
        if (apiData?.data?.records) {
            return apiData?.data?.records.map((record) => ({
                key: record?.id,
                year: record?.year,
                voters: record?.votes,
                candidate: record?.candidate,
                candidatetype: record?.candidatetype?.name,
                candidatetypeId: record?.candidatetypeId,
                percentage: `${record?.percentage}%`,
                constituency: record?.constituency?.name,
                constituencyId: record?.constituency?.id,
                areaName: record?.constituency?.areaName,
                areaCode: record?.constituency?.areaCode,
                district: record?.district?.name,
                districtId: record?.district?.id,
                region: record?.region?.name,
                party: record?.party?.name,
            }));
        }
        return [];
    }, [apiData]);

    useEffect(() => {
        setRecordData(transformedData);
        setFilteredData(transformedData);
    }, [transformedData]);

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        filterData(query, candidateTypeFilter, constituencyFilter, yearFilter);
    };

    const handleCandidateTypeChange = (e) => {
        const selectedType = e.target.value;
        setCandidateTypeFilter(selectedType);
        filterData(searchQuery, selectedType, constituencyFilter, yearFilter);
    };

    const handleConstituencyChange = (e) => {
        const selectedConstituency = e.target.value;
        setConstituencyFilter(selectedConstituency);
        filterData(searchQuery, candidateTypeFilter, selectedConstituency, yearFilter);
    };

    const handleYearChange = (e) => {
        const selectedYear = e.target.value;
        setYearFilter(selectedYear);
        filterData(searchQuery, candidateTypeFilter, constituencyFilter, selectedYear);
    };

    const filterData = (query, type, constituency, year) => {
        const filteredRecords = recordData.filter((record) => {
            const matchesQuery = record.candidate.toLowerCase().includes(query);
            const matchesType = type ? record.candidatetype === type : true;
            const matchesConstituency = constituency ? record.constituencyId.toString() === constituency : true;
            const matchesYear = year ? record.year.toString() === year : true;
            return matchesQuery && matchesType && matchesConstituency && matchesYear;
        });
        setFilteredData(filteredRecords);
    };

    const columns = [
        { title: "Year", dataIndex: "year", sorter: (a, b) => a.year - b.year },
        { title: "Party", dataIndex: "party", sorter: (a, b) => a.party.length - b.party.length },
        { title: "No. of Votes", dataIndex: "voters", sorter: (a, b) => a.voters - b.voters },
        { title: "% of Votes", dataIndex: "percentage", sorter: (a, b) => a.voters - b.voters },
        { title: "Candidate", dataIndex: "candidate", sorter: (a, b) => a.candidate.length - b.candidate.length },
        { title: "Candidate Type", dataIndex: "candidatetype", sorter: (a, b) => a.candidatetype.length - b.candidatetype.length },
        { title: "Constituency", dataIndex: "constituency", sorter: (a, b) => a.constituency.length - b.constituency.length },
        { title: "Region", dataIndex: "region", sorter: (a, b) => a.region.length - b.region.length },
    ];

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Record Management</h4>
                            <h6>Manage Records</h6>
                        </div>
                    </div>
                </div>

                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Search by Candidate Name"
                                        className="form-control form-control-sm formsearch"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                    <Link to="#" className="btn btn-searchset">
                                        <Search className="feather-search" />
                                    </Link>
                                </div>

                                <div className="candidate-type-filter">
                                    <select
                                        value={candidateTypeFilter}
                                        onChange={handleCandidateTypeChange}
                                        className="form-control"
                                    >
                                        <option value="">All Candidate Types</option>
                                        <option value="Parliamentary">Parliamentary</option>
                                        <option value="Presidential">Presidential</option>
                                    </select>
                                </div>

                                <div className="constituency-filter mx-3">
                                    <select
                                        value={constituencyFilter}
                                        onChange={handleConstituencyChange}
                                        className="form-control"
                                    >
                                        <option value="">All Constituencies</option>
                                        {constituenciesList.map((cons) => (
                                            <option key={cons.id} value={cons.id}>
                                                {cons.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="year-filter mx-3">
                                    <select
                                        value={yearFilter}
                                        onChange={handleYearChange}
                                        className="form-control"
                                    >
                                        <option value="">All Years</option>
                                        {[...new Set(recordData.map((record) => record.year))].map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive">
                            <Table
                                className="table table-hover table-center mb-0 datatable"
                                columns={columns}
                                dataSource={filteredData}
                                rowKey={(record) => record.key}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PastRecord;
