import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import useFetch from "../../ApiClient/GetApi";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { PostApi } from "../../ApiClient/PostApi";
import { IMAGE_URL } from "../../Utils/urls";
import { error_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";
import Chart from "react-apexcharts";
import { toWords } from 'number-to-words';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const ResultDisplay = () => {
  const [loading, setLoading] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedConstituency, setSelectedConstituency] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const [partyId, setPartyId] = useState(null);
  const [text, setText] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [constituencies, setConstituencies] = useState([]);
  const [pollingStations, setPollingStations] = useState([]);
  const [type, setType] = useState(null);
  const [reportData, setReportData] = useState(null); // State to hold the API response data
  const [chartList, setChartList] = useState([]);
  const [list, setList] = useState([]);

  const getAllRegions = useFetch("getAllRegions");
  const getAllParties = useFetch("getAllParties");

  const handleRegionChange = async (regionId) => {
    setSelectedRegion(regionId);
    setSelectedDistrict(null);
    setSelectedConstituency(null);
    setPollingStations([]);

    try {
      const response = await PostApi("regionDistricts", { regionId });
      setDistricts(response.data.data.districts || []);
    } catch (error) {
      console.error("Error fetching districts:", error);
    }
  };

  const handleDistrictChange = async (districtId) => {
    setSelectedDistrict(districtId);
    setSelectedConstituency(null);
    setPollingStations([]);

    try {
      const response = await PostApi("districtConsistuency", { districtId });
      setConstituencies(response.data.data.consistuencies || []);
    } catch (error) {
      console.error("Error fetching constituencies:", error);
    }
  };

  const handleConstituencyChange = async (constituencyId) => {
    setSelectedConstituency(constituencyId);
    setPollingStations([]);

    try {
      const response = await PostApi("consistuencyPollingStationsForRecords", { constituencyId });
      setPollingStations(response.data.data.pollingstations || []);
    } catch (error) {
      console.error("Error fetching polling stations:", error);
    }
  };
  const uniqueParties = reportData?.data?.constituencies?.reduce((acc, cons) => {
    cons.parties.forEach((party) => {
      if (!acc.includes(party.partyName)) {
        acc.push(party.partyName);
      }
    });
    return acc;
  }, []);
  const submit = async () => {
    try {
      setLoading(true)
      const response = await PostApi("resultDisplayReport", {
        regionId: selectedRegion,
        districtId: selectedDistrict,
        constituencyId: selectedConstituency,
        pollingStationId: selectedStation,
        type: type,
        partyId
      });
      // console.log(response?.data?.data?.data)
      if (response.data.message == "pollingstation") {
        setText(response.data.message);
        setReportData(response.data.data); // Set the fetched report data
      }
      if (response.data.message == "constituency") {
        setText(response.data.message);
        setReportData(response.data.data); // Set the fetched report data
        setList(response?.data?.data?.data?.candidates);
      }
      if (response.data.message == "district") {
        setText(response.data.message);
        setReportData(response.data.data); // Set the fetched report data
      }
      if (response.data.message == "region") {
        setText(response.data.message);
        setReportData(response.data.data); // Set the fetched report data
      }
      if (response.data.message == "national") {
        setText(response.data.message);
        setReportData(response.data.data); // Set the fetched report data
      }
      setChartList(response?.data?.data?.chartData)

      setLoading(false)
    } catch (error) {
      error_toaster(error.message);
      setLoading(false);
    }
  };
  const getWinningCandidate = () => {
    if (!reportData || !reportData.pollingStationCandidates) return null;
    const winningCandidate = reportData.pollingStationCandidates.reduce((max, candidate) => (parseInt(candidate.voteSecured) > parseInt(max.voteSecured) ? candidate : max), reportData.pollingStationCandidates[0]);
    return winningCandidate;
  };

  const winningCandidate = getWinningCandidate();

  const calculateValidVotes = () => {
    if (!reportData || !reportData?.data?.data?.pollingStationCandidates) return 0;

    return reportData?.data?.data.pollingStationCandidates.reduce((sum, candidateData) => {
      const { voteSecured } = candidateData;
      const secured = voteSecured !== null ? parseInt(voteSecured) : 0;
      return sum + secured;
    }, 0);
  };
  console.log(reportData?.data);
  const isArrayAndNotEmpty = (value) => Array.isArray(value) && value.length > 0;

  const [chartOptions, setChartOptions] = useState({
    series: [
      { name: "Parliamentary", data: [] },
      { name: "Presidential", data: [] },
    ],
    colors: ["#28C76F", "#EA5455"],
    chart: {
      type: "bar",
      height: 320,
      stacked: true,
      zoom: { enabled: true },
    },
    responsive: [
      {
        breakpoint: 280,
        options: {
          legend: {
            position: "bottom",
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
        columnWidth: "20%",
      },
    },
    dataLabels: { enabled: false },
    yaxis: { min: 0, max: 300, tickAmount: 5 },
    xaxis: { categories: [] },
    legend: { show: false },
    fill: { opacity: 1 },
  });

  useEffect(() => {
    if (chartList && chartList.length > 0) {
      const categories = []; // To store party names
      const seriesData = []; // To store votes secured for each party

      let maxVote = 300;

      // Iterate through the chartList to extract party names and their votes secured
      chartList.forEach((party) => {
        categories.push(party.partyName);
        const votesSecured = parseInt(party.totalVotesSecured, 10) || 0;
        seriesData.push(votesSecured);
        maxVote = Math.max(maxVote, votesSecured);
      });

      // Create a single series array with the party names and vote counts
      const seriesArray = [
        {
          name: "Total Votes Secured", // This is the label for the series in the chart
          data: seriesData, // Set the data as the votes secured
        },
      ];

      // Update the chart options with the new series and categories
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        series: seriesArray, // Use the constructed series array
        xaxis: { categories: categories.length > 0 ? categories : [""] }, // Set the categories as the party names
        yaxis: { ...prevOptions.yaxis, max: maxVote + 50 }, // Adjust the y-axis max value
      }));
    }
  }, [chartList]);


  const [ConstchartOptions, setConstChartOptions] = useState({
    series: [
      { name: "Total Votes Secured", data: [] }
    ],
    colors: ["#28C76F"],
    chart: {
      type: "bar",
      height: 320,
      stacked: false,
      zoom: { enabled: true },
    },
    responsive: [
      {
        breakpoint: 280,
        options: {
          legend: {
            position: "bottom",
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
        columnWidth: "20%",
      },
    },
    dataLabels: { enabled: false },
    yaxis: { min: 0, max: 3000, tickAmount: 5 },
    xaxis: { categories: [] },
    legend: { show: false },
    fill: { opacity: 1 },
  });

  useEffect(() => {
    if (list && list.length > 0) {
      const categories = [];
      const totalVotesSecured = [];

      list.forEach((candidate) => {
        categories.push(candidate.name);
        totalVotesSecured.push(candidate.totalVotesSecured);
      });

      setConstChartOptions((prevOptions) => ({
        ...prevOptions,
        series: [{ name: "Total Votes Secured", data: totalVotesSecured }],
        xaxis: { categories: categories.length > 0 ? categories : [""] },
        yaxis: { ...prevOptions.yaxis, max: Math.max(...totalVotesSecured) + 100 },
      }));
    }
  }, [list]);

  console.log(JSON.stringify(reportData))
  console.log((reportData))


  const handleExportPDF = () => {
    const doc = new jsPDF();

    // Get current date
    const currentDate = new Date().toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    // Set PDF title
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text(`Election Results Report of ${text ? text.toUpperCase() : ''}`, 14, 10);
    doc.setFontSize(10);
    doc.text(`Candidate Type: ${type || ''}`, 14, 18);

    // Add current date to the corner
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text(currentDate, doc.internal.pageSize.width - 30, 10, null, null, 'right');

    // Add Total Votes Cast and Wrong Votes information
    if (text === "constituency") {
      if (reportData.data) {
        doc.setFont("helvetica", "bold");
        doc.text(`Total Votes Cast: ${reportData.data.totalVotesCast || 0}`, 14, 24);
        doc.text(`Total Wrong Votes: ${reportData.data.totalWrongVotes || 0}`, 14, 30);
      }

      // Add Constituency Information in table format
      if (reportData.constData && reportData.constData.district && reportData.constData.district.region) {
        const constituencyTableData = [
          { label: "District", value: reportData.constData.district.name },
          { label: "Region", value: reportData.constData.district.region.name },
          { label: "Constituency Name", value: reportData.constData.name },
        ];

        doc.setFont("helvetica", "bold");
        doc.text("Constituency Information:", 14, 40);
        doc.autoTable({
          startY: 50,
          head: [["Label", "Value"]],
          body: constituencyTableData.map(({ label, value }) => [label, value]),
        });
      } else {
        doc.setFont("helvetica", "bold");
        doc.text("Constituency Information Not Available", 14, 40);
      }

      // Calculate percentage of announced polling stations
      if (reportData.counts && reportData.counts.totalPollingStations != null && reportData.counts.stationsWithNoResults != null) {
        const { totalPollingStations, stationsWithNoResults } = reportData.counts;
        const announcedPollingStations = totalPollingStations - stationsWithNoResults;
        const pollingStationResultPercentage = totalPollingStations > 0 ? ((announcedPollingStations / totalPollingStations) * 100).toFixed(2) : 0;

        // Add Polling Station details as a table
        const pollingStationStartY = doc.autoTable.previous.finalY + 10; // Set startY for Polling Station section
        doc.setFont("helvetica", "bold");
        doc.text("Polling Station Statistics:", 14, pollingStationStartY);

        // Prepare Polling Station Information table headers
        const pollingStationTableColumn = ["Total Polling Stations", "Announced Polling Stations", "Announcement Percentage"];
        const pollingStationTableRows = [[totalPollingStations, announcedPollingStations, `${pollingStationResultPercentage}%`]];

        // Add Polling Station Information table to the PDF
        doc.autoTable({
          head: [pollingStationTableColumn],
          body: pollingStationTableRows,
          startY: pollingStationStartY + 10,
        });
      } else {
        doc.setFont("helvetica", "bold");
        doc.text("Polling Station Information Not Available", 14, doc.autoTable.previous.finalY + 10);
      }

      // Add margin between Polling Station and Candidates sections
      const candidatesStartY = doc.autoTable.previous.finalY + 20; // Set startY for Candidates section

      // Section 3: Candidates Results
      if (reportData.data && reportData.data.candidates && reportData.data.candidates.length > 0) {
        doc.setFont("helvetica", "bold");
        doc.text("Candidates Results", 14, candidatesStartY);
        doc.setFont("helvetica", "normal");

        // Prepare Candidates Results table headers
        const candidateTableColumn = ["Candidate Name", "Party", "Votes Secured", "Percentage"];

        // Prepare Candidates Results table rows
        const candidateTableRows = reportData.data.candidates.map(candidate => [
          candidate.name || "",
          candidate.party ? candidate.party.name : "",
          candidate.totalVotesSecured || "",
          candidate.votePercentage ? `${candidate.votePercentage}%` : ""
        ]);

        // Add Candidates Results table to the PDF
        doc.autoTable({
          head: [candidateTableColumn],
          body: candidateTableRows,
          startY: candidatesStartY + 10,
        });
      } else {
        doc.setFont("helvetica", "bold");
        doc.text("Candidates Information Not Available", 14, candidatesStartY);
      }
    } else if (text === "district") {
      // Add Total Votes Cast and Wrong Votes information
      if (reportData.data) {
        doc.setFont("helvetica", "bold");
        doc.text(`Total Votes Cast: ${reportData.data.totalVotesCast || 0}`, 14, 24);
        doc.text(`Total Wrong Votes: ${reportData.data.totalWrongVotes || 0}`, 14, 30);
      }

      // Add District Information in table format
      if (reportData.districtData && reportData.districtData.name && reportData.districtData.region && reportData.constituencies) {
        const districtTableData = [
          { label: "District", value: reportData.districtData.name },
          { label: "Region", value: reportData.districtData.region.name },
        ];

        doc.setFont("helvetica", "bold");
        doc.text("District Information:", 14, 40);
        doc.autoTable({
          startY: 50,
          head: [["Label", "Value"]],
          body: districtTableData.map(({ label, value }) => [label, value]),
        });

        // Add Constituencies Information in table format
        const constituenciesTableData = reportData.constituencies.map(constituency => [
          constituency.name,
          constituency.totalVoters,
          constituency.totalPollingStations
        ]);

        doc.setFont("helvetica", "bold");
        doc.text("Constituencies Information:", 14, doc.autoTable.previous.finalY + 10);
        doc.autoTable({
          head: [["Constituency Name", "Total Voters", "Total Polling Stations"]],
          body: constituenciesTableData,
          startY: doc.autoTable.previous.finalY + 20,
        });



        // Add Chart Data if available
        if (reportData.chartData && reportData.chartData.length > 0) {
          const chartData = reportData.chartData.map(item => [
            item.partyName,
            item.totalVotesSecured
          ]);

          doc.setFont("helvetica", "bold");
          doc.text("Chart Data:", 14, doc.autoTable.previous.finalY + 10);
          doc.autoTable({
            head: [["Party Name", "Total Votes Secured"]],
            body: chartData,
            startY: doc.autoTable.previous.finalY + 20,
          });
        } else {
          doc.setFont("helvetica", "bold");
          doc.text("Chart Data Not Available", 14, doc.autoTable.previous.finalY + 10);
        }
      } else {
        doc.setFont("helvetica", "bold");
        doc.text("District Information Not Available", 14, 40);
      }
    }

    // Save the PDF
    doc.save("election_results_report.pdf");
  };
  const handleExportRegionPDF = () => {
    const doc = new jsPDF();

    // Get current date
    const currentDate = new Date().toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    // Set PDF title
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text(`Region Data Report`, 14, 10);

    doc.setFontSize(10);
    doc.text(`Candidate Type: ${type || ''}`, 14, 18);

    // Add current date to the corner
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text(currentDate, doc.internal.pageSize.width - 30, 10, null, null, 'right');

    // Add Total Votes Cast and Wrong Votes information
    if (reportData.data) {
        doc.setFont("helvetica", "normal");
        doc.text(`Total Votes Cast: ${reportData.data.totalVotesCast || 0}`, 14, 24);
        doc.text(`Total Wrong Votes: ${reportData.data.totalWrongVotes || 0}`, 14, 30);
    }

    // Add Constituencies Information
    if (Array.isArray(reportData.data.constituencies) && reportData.data.constituencies.length > 0) {
        reportData.data.constituencies.forEach((constituency, index) => {
            const startY = index === 0 ? 40 : doc.autoTable.previous.finalY + 20;

            // Constituency Name and Created At
            doc.setFont("helvetica", "bold");
            doc.text(`Constituency Name: ${constituency.constituencyName || 'N/A'}`, 14, startY);
            // doc.text(`Created At: ${constituency.createdAt ? new Date(constituency.createdAt).toLocaleDateString('en-US') : 'N/A'}`, 14, startY + 6);

            // Party Results Table
            const partyResultsTable = [];

            // Ensure parties exist and is an array
            if (Array.isArray(constituency.parties)) {
                // Prepare Party Results Data dynamically
                constituency.parties.forEach((party) => {
                    partyResultsTable.push([party.partyName || 'N/A', party.totalVotesSecured || 'N/A']);
                });

                // Add Party Results Table to PDF
                doc.autoTable({
                    startY: startY + 10,
                    head: [['Party Name', 'Votes Secured']],
                    body: partyResultsTable
                });
            }

            // Polling Stations Table (if needed)
            // const pollingStationsTable = [];
            // if (Array.isArray(constituency.pollingstations)) {
            //     constituency.pollingstations.forEach(station => {
            //         pollingStationsTable.push([station.name || 'N/A', station.totalVoters || 'N/A']);
            //     });
            //     doc.autoTable({
            //         startY: doc.autoTable.previous.finalY + 10,
            //         head: [['Polling Station Name', 'Total Voters']],
            //         body: pollingStationsTable
            //     });
            // }

            // Add a page break if it's not the last constituency
            if (index < reportData.data.constituencies.length - 1) {
                // doc.addPage();
            }
        });
    } else {
        doc.setFont("helvetica", "bold");
        doc.text("No Constituency Information Available", 14, 24);
    }

    // Add Station Results if available
    if (reportData.stationResults) {
        doc.text(`Total Polling Stations: ${reportData.stationResults.totalPollingStations || 'N/A'}`, 14, doc.autoTable.previous.finalY + 20);
        doc.text(`Polling Stations with Results: ${reportData.stationResults.pollingStationsWithResults || 'N/A'}`, 14, doc.autoTable.previous.finalY + 26);
        doc.text(`Polling Stations without Results: ${reportData.stationResults.pollingStationsWithoutResults || 'N/A'}`, 14, doc.autoTable.previous.finalY + 32);
        doc.text(`Polling Stations with Results Percentage: ${reportData.stationResults.pollingStationsWithResultsPercentage || 'N/A'}`, 14, doc.autoTable.previous.finalY + 38);
        doc.text(`Polling Stations without Results Percentage: ${reportData.stationResults.pollingStationsWithoutResultsPercentage || 'N/A'}`, 14, doc.autoTable.previous.finalY + 44);
    }

    // Save the PDF
    doc.save("region_data_report.pdf");
};


const handleExportPollingStationPDF = () => {
  const doc = new jsPDF();

  // Get current date
  const currentDate = new Date().toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
  });

  // Set PDF title
  doc.setFontSize(14);
  doc.setFont("helvetica", "bold");
  doc.text(`Polling Station Data Report`, 14, 10);
  doc.setFontSize(10);
  doc.text(`Candidate Type: ${type || ''}`, 14, 18);

  // Polling Station Information
  doc.setFontSize(10);
  doc.text(`Polling Station: ${reportData.data.data.dd.name || 'N/A'}`, 14, 24);
  doc.text(`Polling Station Code: ${reportData.data.data.dd.code || 'N/A'}`, 14, 28);

  // Add current date to the corner
  doc.setFontSize(10);
  doc.setTextColor(100);
  doc.text(currentDate, doc.internal.pageSize.width - 30, 10, null, null, 'right');

  // Total Voters and Votes Cast information
  doc.setFont("helvetica", "normal");
  doc.text(`Total Voters: ${reportData.data.data.dd.totalVoters || 'N/A'}`, 14, 36);

  // Calculate Votes Cast
  let votesCast = 0;

  // Sum up voteSecured for each candidate
  if (Array.isArray(reportData.data.data.pollingStationCandidates)) {
      reportData.data.data.pollingStationCandidates.forEach(candidate => {
          if (candidate.voteSecured) {
              votesCast += parseInt(candidate.voteSecured);
          }
      });
  }

  // Add Wrong Votes
  let wrongVotes = parseInt(reportData.data.data.resultData?.wrongVotes) || 0;
  votesCast += wrongVotes;

  doc.text(`Votes Cast: ${votesCast}`, 14, 40);

  // Candidates and Vote Secured Table
  if (Array.isArray(reportData.data.data.pollingStationCandidates) && reportData.data.data.pollingStationCandidates.length > 0) {
      const candidateData = reportData.data.data.pollingStationCandidates.map(candidate => ({
          candidateName: candidate.candidate.name || 'N/A',
          partyName: candidate.candidate.party.name || 'N/A',
          candidateType: candidate.candidate.candidatetype.name || 'N/A',
          voteSecured: candidate.voteSecured || 'N/A'
      }));

      // Prepare candidate table data
      const candidatesTable = [];
      candidateData.forEach(candidate => {
          candidatesTable.push([candidate.candidateName, candidate.partyName, candidate.candidateType, candidate.voteSecured]);
      });

      // Add Candidates Table to PDF
      doc.autoTable({
          startY: 50,
          head: [['Candidate Name', 'Party Name', 'Candidate Type', 'Votes Secured']],
          body: candidatesTable,
          theme: 'striped',
          styles: { overflow: 'linebreak' },
          columnStyles: {
              0: { cellWidth: 'auto' },
              1: { cellWidth: 'auto' },
              2: { cellWidth: 'auto' },
              3: { cellWidth: 'auto' }
          }
      });
  } else {
      doc.setFont("helvetica", "bold");
      doc.text("No Candidate Information Available", 14, 50);
  }

  // Save the PDF
  doc.save("polling_station_data_report.pdf");
};


const handleExportNationalReportPDF = () => {
  const doc = new jsPDF();
  const leftMargin = 20; // Left margin
  const topMargin = 20;  // Top margin
  const lineSpacing = 8; // Space between lines

  // Get current date
  const currentDate = new Date().toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
  });

  // Set PDF title
  doc.setFontSize(14);
  doc.setFont("helvetica", "bold");
  doc.text(`National Report Data`, leftMargin, topMargin);
  doc.setFontSize(10);
  doc.text(`Candidate Type: ${type || 'N/A'}`, leftMargin, topMargin + 6);
  doc.setFontSize(10);

  // Add current date to the top-right corner
  doc.setFontSize(10);
  doc.setTextColor(100);
  doc.text(currentDate, doc.internal.pageSize.width - leftMargin, topMargin, null, null, 'right');

  // Polling Station Information
  doc.setFont("helvetica", "normal");
  let currentY = topMargin + lineSpacing * 2; // Start position for the first line after title and candidate type
  doc.text(`Total Polling Stations: ${reportData.counts.totalPollingStations}`, leftMargin, currentY);
  currentY += lineSpacing;
  doc.text(`Stations With No Results: ${reportData.counts.stationsWithNoResults}`, leftMargin, currentY);
  currentY += lineSpacing;
  doc.text(`Total Votes Cast: ${reportData.data.totalVotesCast}`, leftMargin, currentY);
  currentY += lineSpacing;
  doc.text(`Total Wrong Votes: ${reportData.data.totalWrongVotes}`, leftMargin, currentY);
  currentY += lineSpacing;
  doc.text(`Regions Count: ${reportData.regionCount}`, leftMargin, currentY);
  currentY += lineSpacing;
  doc.text(`Districts Count: ${reportData.districtCount}`, leftMargin, currentY);
  currentY += lineSpacing;
  doc.text(`Constituency Count: ${reportData.constituencyCount}`, leftMargin, currentY);
  currentY += lineSpacing;

  // Candidates and Vote Secured Table
  if (Array.isArray(reportData.data.partyData) && reportData.data.partyData.length > 0) {
      const partyData = reportData.data.partyData.map(party => ({
          partyName: party.partyName || 'N/A',
          totalVotesSecured: party.totalVotesSecured || 'N/A',
          constituenciesWon: party.constituenciesWon || 'N/A',
          constituenciesLost: party.constituenciesLost || 'N/A',
          votePercentage: party.votePercentage || 'N/A'
      }));

      // Prepare party table data
      const partiesTable = [];
      partyData.forEach(party => {
          partiesTable.push([party.partyName, party.totalVotesSecured, party.constituenciesWon, party.constituenciesLost, party.votePercentage]);
      });

      // Add Parties Table to PDF
      doc.autoTable({
          startY: currentY + 10, // Adjusted start position for the table
          margin: { left: leftMargin, right: leftMargin }, // Set left and right margins
          head: [['Party Name', 'Total Votes Secured', 'Constituencies Won', 'Constituencies Lost', 'Vote Percentage']],
          body: partiesTable,
          theme: 'striped',
          styles: { overflow: 'linebreak' },
          columnStyles: {
              0: { cellWidth: 'auto' },
              1: { cellWidth: 'auto' },
              2: { cellWidth: 'auto' },
              3: { cellWidth: 'auto' },
              4: { cellWidth: 'auto' }
          }
      });
  } else {
      doc.setFont("helvetica", "bold");
      doc.text("No Party Information Available", leftMargin, currentY + 10);
  }

  // Save the PDF
  doc.save("national_report_data.pdf");
};

const handleExportNationalReportPDFPresidential = () => {
  const doc = new jsPDF();
  const leftMargin = 20; // Left margin
  const topMargin = 20;  // Top margin
  const lineSpacing = 8; // Space between lines

  // Get current date
  const currentDate = new Date().toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
  });

  // Set PDF title
  doc.setFontSize(14);
  doc.setFont("helvetica", "bold");
  doc.text(`National Report Data`, leftMargin, topMargin);
  doc.setFontSize(10);
  doc.text(`Candidate Type: ${type || 'N/A'}`, leftMargin, topMargin + 6);
  doc.setFontSize(10);

  // Add current date to the top-right corner
  doc.setFontSize(10);
  doc.setTextColor(100);
  doc.text(currentDate, doc.internal.pageSize.width - leftMargin, topMargin, null, null, 'right');

  // Polling Station Information
  doc.setFont("helvetica", "normal");
  let currentY = topMargin + lineSpacing * 2; // Start position for the first line after title and candidate type
  doc.text(`Total Polling Stations: ${reportData.counts.totalPollingStations}`, leftMargin, currentY);
  currentY += lineSpacing;
  doc.text(`Stations With No Results: ${reportData.counts.stationsWithNoResults}`, leftMargin, currentY);
  currentY += lineSpacing;
  doc.text(`Total Votes Cast: ${reportData.data.totalVotesCast}`, leftMargin, currentY);
  currentY += lineSpacing;
  doc.text(`Total Wrong Votes: ${reportData.data.totalWrongVotes}`, leftMargin, currentY);
  currentY += lineSpacing;
  doc.text(`Total Voters: ${reportData.data.totalVoters}`, leftMargin, currentY);
  currentY += lineSpacing;
  doc.text(`Total Constituencies: ${reportData.data.totalConstituencies}`, leftMargin, currentY);
  currentY += lineSpacing;
  doc.text(`Regions Count: ${reportData.regionCount}`, leftMargin, currentY);
  currentY += lineSpacing;
  doc.text(`Districts Count: ${reportData.districtCount}`, leftMargin, currentY);
  currentY += lineSpacing;
  doc.text(`Constituency Count: ${reportData.constituencyCount}`, leftMargin, currentY);
  currentY += lineSpacing;

  // Candidates and Vote Secured Table
  if (Array.isArray(reportData.data.candidates) && reportData.data.candidates.length > 0) {
      const candidateData = reportData.data.candidates.map(candidate => ({
          candidateName: candidate.candidateName || 'N/A',
          partyName: candidate.partyName || 'N/A',
          totalVotesSecured: candidate.totalVotesSecured || 'N/A',
          constituenciesWon: candidate.constituenciesWon || 'N/A',
          votePercentage: candidate.votePercentage || 'N/A'
      }));

      // Prepare candidate table data
      const candidatesTable = [];
      candidateData.forEach(candidate => {
          candidatesTable.push([candidate.candidateName, candidate.partyName, candidate.totalVotesSecured, candidate.constituenciesWon, candidate.votePercentage]);
      });

      // Add Candidates Table to PDF
      doc.autoTable({
          startY: currentY + 10, // Adjusted start position for the table
          margin: { left: leftMargin, right: leftMargin }, // Set left and right margins
          head: [['Candidate Name', 'Party Name', 'Total Votes Secured', 'Constituencies Won', 'Vote Percentage']],
          body: candidatesTable,
          theme: 'striped',
          styles: { overflow: 'linebreak' },
          columnStyles: {
              0: { cellWidth: 'auto' },
              1: { cellWidth: 'auto' },
              2: { cellWidth: 'auto' },
              3: { cellWidth: 'auto' },
              4: { cellWidth: 'auto' }
          }
      });
  } else {
      doc.setFont("helvetica", "bold");
      doc.text("No Candidate Information Available", leftMargin, currentY + 10);
  }

  // Save the PDF
  doc.save("national_report_data.pdf");
};





  return (
    <div className="page-wrapper">
      {
        loading ? <Loader /> : <div>
          <ToastContainer />
          <div className="content">
            <div className="page-header">
              <div className="add-item d-flex">
                <div className="page-title">
                  <h4>Results Display</h4>
                  <h6>View and Analyze Results</h6>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <div className="">
                {
                  (text == "constituency" || text == "district") && <button className="btn btn-success" onClick={handleExportPDF}>
                    Export to PDF
                  </button>
                }
                {
                  text == "region" && <button className="btn btn-success" onClick={handleExportRegionPDF}>
                    Export to PDF Region
                  </button>
                }
                {
                  text == "pollingstation" && <button className="btn btn-success" onClick={handleExportPollingStationPDF}>
                    Export to PDF P.S
                  </button>
                }
                {
                 ( text == "national" && type=="Presidential") && <button className="btn btn-success" onClick={handleExportNationalReportPDFPresidential}>
                    Export to PDF Pres
                  </button>
                }
                {
                 ( text == "national" && type=="Parliamentary") && <button className="btn btn-success" onClick={handleExportNationalReportPDF}>
                    Export to PDF Pres
                  </button>
                }
              </div>
            </div>
            {/* Select Inputs for Regions, Districts, Constituencies, and Polling Stations */}
            <div className="row mb-4">
              <div className="col-md-3">
                <div className="form-group">
                  <label>Candidate Type</label>
                  <select
                    name="candidateType"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    className="form-control"
                  >
                    <option value="">Select Candidate Type</option>
                    <option value="Parliamentary">Parliamentary</option>
                    <option value="Presidential">Presidential</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <label>Party</label>
                <select className="form-control" value={partyId || ""} onChange={(e) => setPartyId(e.target.value)}>
                  <option value="">Select Party</option>
                  {getAllParties?.apiData?.data?.parties?.map((party) => (
                    <option key={party.id} value={party.id}>
                      {party.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
                <label>Region</label>
                <select className="form-control" value={selectedRegion || ""} onChange={(e) => handleRegionChange(e.target.value)}>
                  <option value="">Select Region</option>
                  {getAllRegions?.apiData?.data?.regions?.map((region) => (
                    <option key={region.id} value={region.id}>
                      {region.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-3">
                <label>District</label>
                <select className="form-control" value={selectedDistrict || ""} onChange={(e) => handleDistrictChange(e.target.value)} disabled={!selectedRegion}>
                  <option value="">Select District</option>
                  {districts.map((district) => (
                    <option key={district.id} value={district.id}>
                      {district.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-3">
                <label>Constituency</label>
                <select className="form-control" value={selectedConstituency || ""} onChange={(e) => handleConstituencyChange(e.target.value)} disabled={!selectedDistrict}>
                  <option value="">Select Constituency</option>
                  {constituencies.map((constituency) => (
                    <option key={constituency.id} value={constituency.id}>
                      {constituency.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-3">
                <label>Polling Station</label>
                <select onChange={(e) => setSelectedStation(e.target.value)} defaultValue={selectedStation} className="form-control" disabled={!selectedConstituency}>
                  <option value="">Select Polling Station</option>
                  {pollingStations.map((station) => (
                    <option key={station.id} value={station.id}>
                      {station.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
                <label htmlFor=""></label>
                <div>
                  <button onClick={submit} className="btn btn-warning">Submit</button>
                </div>
              </div>
            </div>

            {
              reportData && text == "national" && (
                <div>
                  <div className="row mb-4">
                    {/* Region Count */}
                    <div className="col-md-3">
                      <div className="card text-white bg-primary mb-3">
                        <div className="card-body">
                          <h5 className="card-title">No. of Regions</h5>
                          <p className="card-text">{reportData?.regionCount || 0}</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="card text-white bg-success mb-3">
                        <div className="card-body">
                          <h5 className="card-title">No. of Constituencies</h5>
                          <p className="card-text">{reportData?.constituencyCount || 0}</p>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3">
                      <div className="card text-white bg-secondary mb-3">
                        <div className="card-body">
                          <h5 className="card-title">Total Votes Cast</h5>
                          <p className="card-text">{parseInt(reportData?.data?.totalVotesCast) + parseInt(reportData?.data?.totalWrongVotes)}</p>
                        </div>
                      </div>
                    </div>

                    {/* Total Rejected Votes */}

                    <div className="col-md-3">
                      <div className="card text-white bg-warning mb-3">
                        <div className="card-body">
                          <h5 className="card-title">Total Rejected Votes</h5>
                          <p className="card-text">{reportData?.data?.totalWrongVotes || 0}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card text-white bg-info mb-3">
                        <div className="card-body">
                          <h5 className="card-title">Total Polling Stations</h5>
                          <p className="card-text">{reportData?.counts?.totalPollingStations}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card text-white bg-success mb-3">
                        <div className="card-body">
                          <h5 className="card-title">P.S with results</h5>
                          <p className="card-text">{Number(reportData?.counts?.totalPollingStations ?? 0) - Number(reportData?.counts?.stationsWithNoResults ?? 0)}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card text-white bg-secondary mb-3">
                        <div className="card-body">
                          <h5 className="card-title">P.S with no results</h5>
                          <p className="card-text">{reportData?.counts?.stationsWithNoResults ?? 0}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card text-white bg-warning mb-3">
                        <div className="card-body">
                          <h5 className="card-title">P.S %</h5>
                          <p className="card-text"> {(
                            ((Number(reportData?.counts?.totalPollingStations ?? 0) - Number(reportData?.counts?.stationsWithNoResults ?? 0)) /
                              Number(reportData?.counts?.totalPollingStations ?? 1)) * 100
                          ).toFixed(2)}%</p>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="card mb-4 shadow">
                    <div className="card-body">
                      <h5 className="card-title">{type === "Presidential" ? "Candidates Data" : "Parties Data"}</h5>
                      <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                          <tr>
                            {type === "Presidential" ? (
                              <>
                                <th>Candidate Name</th>
                                <th>Party Name</th>
                                <th>Total Votes Secured</th>
                                <th>Constituencies Won</th>
                                <th>Vote Percentage</th>
                              </>
                            ) : (
                              <>
                                <th>Party Name</th>
                                <th>Total Votes Secured</th>
                                <th>Constituencies Won</th>
                                <th>Constituencies Lost</th>
                                <th>Vote Percentage</th>
                              </>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {type === "Presidential" ? (
                            isArrayAndNotEmpty(reportData?.data?.candidates) ? (
                              reportData.data.candidates.map((candidate) => (
                                <tr key={candidate?.candidateName}>
                                  <td>{candidate?.candidateName || "N/A"}</td>
                                  <td>{candidate?.partyName || "N/A"}</td>
                                  <td>{candidate?.totalVotesSecured || 0}</td>
                                  <td>{candidate?.constituenciesWon || 0}</td>
                                  <td>{candidate?.votePercentage || "0.00%"}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5">No data available</td>
                              </tr>
                            )
                          ) : (
                            // Check the correct path for partyData
                            isArrayAndNotEmpty(reportData?.data?.partyData) ? (
                              reportData.data.partyData?.map((party) => (
                                <tr key={party?.partyName}>
                                  <td>{party?.partyName || "N/A"}</td>
                                  <td>{party?.totalVotesSecured || 0}</td>
                                  <td>{party?.constituenciesWon || 0}</td>
                                  <td>{party?.constituenciesLost || 0}</td>
                                  <td>{party?.votePercentage || "0.00%"}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5">No data available</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>


                  </div>

                </div>
              )
            }
            {reportData && text === "pollingstation" && (
              <>
                {/* Polling Station Summary Section */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <div className="card text-white bg-warning mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Polling Station</h5>
                        <p className="card-text">{reportData?.data?.data?.dd?.name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-info mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Total Voters</h5>
                        <p className="card-text">{reportData?.data?.data?.dd?.totalVoters}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-secondary mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Constituency</h5>
                        <p className="card-text">{reportData?.data?.data?.dd?.constituency?.name}</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                    <div className="card text-white bg-success mb-3">
                      <div className="card-body">
                        <h5 className="card-title">District</h5>
                        <p className="card-text">{reportData?.data?.data?.dd?.constituency?.district?.name}</p>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-3">
                    <div className="card text-white bg-success mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Region</h5>
                        <p className="card-text">{reportData?.data?.data?.dd?.constituency?.district?.region?.name}</p>
                      </div>
                    </div>
                  </div>

                  {/* Winner Candidate Section */}
                  {winningCandidate && (
                    <>
                      <div className="col-md-3">
                        <div className="card text-white bg-info mb-3">
                          <div className="card-body">
                            <h5 className="card-title">Winner Candidate</h5>
                            <p className="card-text">{winningCandidate.candidate.name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="card text-white bg-secondary mb-3">
                          <div className="card-body">
                            <h5 className="card-title">Winner Votes</h5>
                            <p className="card-text">{winningCandidate.voteSecured}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="card text-white bg-success mb-3">
                          <div className="card-body">
                            <h5 className="card-title">Winner Party</h5>
                            <p className="card-text">{winningCandidate.candidate.party.name}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {/* Polling Station Candidates Table */}
                <div>
                  <div className="card shadow">
                    <div className="card-body">
                      <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                          <tr>
                            <th>Candidate Name</th>
                            <th>Party</th>
                            <th>No. Votes Secured (In Figures)</th>
                            <th>No. Votes Secured (In Words)</th>

                          </tr>
                        </thead>
                        <tbody>
                          {reportData?.data?.data?.pollingStationCandidates?.map((candidateData) => {
                            const { candidate, voteSecured } = candidateData;
                            return (
                              <tr key={candidate.id}>
                                <td>{candidate.name}</td>
                                <td>
                                  <div>
                                    <img
                                      src={IMAGE_URL + candidate?.party?.image}
                                      style={{ height: "40px", width: "40px", objectFit: "contain" }}
                                      alt={candidate?.party?.name}
                                    />
                                  </div>
                                </td>
                                <td>{voteSecured ?? 0}</td>
                                <td>{toWords(voteSecured ?? 0)}</td>

                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan="2">Total Valid Ballot</td>
                            <td>{calculateValidVotes()}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td colSpan="2">Total Rejected Ballot</td>
                            <td>{reportData?.data?.data?.resultData?.wrongVotes}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td colSpan="2">Total Ballot Cast</td>
                            <td>{parseInt(calculateValidVotes()) + parseInt(reportData?.data?.data?.resultData?.wrongVotes ?? 0)}</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}

            {reportData && text == "constituency" && (
              <div className="report-section">
                <div className="row">

                  <div className="col-md-3">
                    <div className="card text-white bg-secondary">
                      <div className="card-body">
                        <h5 className="card-title">Total Polling Stations</h5>
                        <p className="card-text">{reportData?.counts?.totalPollingStations}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-info">
                      <div className="card-body">
                        <h5 className="card-title">P.S with Results</h5>
                        <p className="card-text">{Number(reportData?.counts?.totalPollingStations ?? 0) - Number(reportData?.counts?.stationsWithNoResults ?? 0)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-success">
                      <div className="card-body">
                        <h5 className="card-title">P.S without Results</h5>
                        <p className="card-text">{reportData?.counts?.stationsWithNoResults ?? 0}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-warning">
                      <div className="card-body">
                        <h5 className="card-title">% of P.S</h5>
                        <p className="card-text"> {(
                          ((Number(reportData?.counts?.totalPollingStations ?? 0) - Number(reportData?.counts?.stationsWithNoResults ?? 0)) /
                            Number(reportData?.counts?.totalPollingStations ?? 1)) * 100
                        ).toFixed(2)}%</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Candidates Table */}
                <div className="card mb-4 shadow">
                  <div className="card-body">
                    <h5 className="card-title">Candidates Information</h5>
                    <div className="table-responsive"></div>
                    <table className="table table-striped table-bordered mt-4">
                      <thead className="thead-dark">
                        <tr>
                          <th>Candidate Name</th>
                          <th>Party</th>

                          <th>No. Votes Secured (In Figures)</th>
                          <th>No. Votes Secured (In Words)</th>
                          <th>% of Votes Secured</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reportData.data.candidates.map((candidate) => (
                          <tr key={candidate.id}>
                            <td>{candidate?.name}</td>
                            <td>
                              <div>
                                <img
                                  style={{ height: "40px", width: "40px", objectFit: "contain" }}
                                  src={IMAGE_URL + candidate.party.image}
                                  alt={candidate?.party?.name}
                                />
                              </div>
                            </td>

                            <td>{candidate.totalVotesSecured}</td>
                            <td>{toWords(candidate.totalVotesSecured)}</td>
                            <td>{candidate.votePercentage}%</td>
                          </tr>
                        ))}
                        {/* Display total valid votes, wrong votes, and total vote cast */}
                        <tr>
                          <td colSpan="3" className="text-right"><strong>Total Valid Votes</strong></td>
                          <td><strong>{reportData.data.candidates.reduce((acc, candidate) => acc + candidate.totalVotesSecured, 0)}</strong></td>
                          {/* <td><strong>{toWords(reportData.data.candidates.reduce((acc, candidate) => acc + candidate.totalVotesSecured, 0))}</strong></td> */}
                          <td></td>
                        </tr>
                        <tr>
                          <td colSpan="3" className="text-right"><strong>Total Rejected Votes</strong></td>
                          <td colSpan="3"><strong>{reportData.data.totalWrongVotes}</strong></td>
                        </tr>
                        <tr>
                          <td colSpan="3" className="text-right"><strong>Total Votes Cast</strong></td>
                          <td colSpan="3"><strong>{parseInt(reportData.data.candidates.reduce((acc, candidate) => acc + candidate.totalVotesSecured, 0)) + parseInt(reportData.data.totalWrongVotes)}</strong></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {reportData && text == "district" && (
              <div className="report-section">
                <div className="row mb-4">
                  <div className="col-md-3">
                    <div className="card text-white bg-warning mb-3">
                      <div className="card-body">
                        <h5 className="card-title">District</h5>
                        <p className="card-text">{reportData?.districtData?.name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-info mb-3">
                      <div className="card-body">
                        <h5 className="card-title">No. of Constituencies</h5>
                        <p className="card-text">{reportData?.constLength}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-success mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Total Votes Cast</h5>
                        <p className="card-text">{parseInt(reportData?.data?.totalVotesCast) + parseInt(reportData?.data?.totalWrongVotes)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-secondary mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Total Rejected Votes</h5>
                        <p className="card-text">{reportData?.data?.totalWrongVotes}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Candidates Table */}
                <div className="card mb-4 shadow">
                  <div className="card-body">
                    <h5 className="card-title">Constituency and Party Votes ({type})</h5>
                    <table className="table table-striped table-bordered">
                      <thead className="thead-dark">
                        <tr>
                          <th>Constituency Name</th>
                          {uniqueParties?.map((partyName) => (
                            <th key={partyName}>{partyName}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {reportData?.data?.constituencies?.map((constituency) => (
                          <tr key={constituency.constituencyName}>
                            <td>{constituency.constituencyName}</td>
                            {uniqueParties?.map((partyName) => {
                              const party = constituency.parties.find((p) => p.partyName === partyName);
                              return (
                                <td key={partyName}>
                                  {party ? party.totalVotesSecured : 0}  - ({party ? party.votePercentage : 0})
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {reportData && text == "region" && (
              <div className="report-section">
                <div className="row mb-4">
                  <div className="col-md-3">
                    <div className="card text-white bg-warning mb-3">
                      <div className="card-body">
                        <h5 className="card-title">No. of Districts</h5>
                        <p className="card-text">{reportData?.districts}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-info mb-3">
                      <div className="card-body">
                        <h5 className="card-title">No. of P.S</h5>
                        <p className="card-text">{reportData?.stationResults?.totalPollingStations}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-success mb-3">
                      <div className="card-body">
                        <h5 className="card-title">P.S with result</h5>
                        <p className="card-text">{reportData?.stationResults?.pollingStationsWithResults}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-secondary mb-3">
                      <div className="card-body">
                        <h5 className="card-title">P.S with no result</h5>
                        <p className="card-text">{reportData?.stationResults?.pollingStationsWithoutResults}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-warning mb-3">
                      <div className="card-body">
                        <h5 className="card-title">P.S result %</h5>
                        <p className="card-text">{reportData?.stationResults?.pollingStationsWithResultsPercentage}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-info mb-3">
                      <div className="card-body">
                        <h5 className="card-title">No. of Constituencies</h5>
                        <p className="card-text">{reportData?.constLength}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-success mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Total Votes Cast</h5>
                        <p className="card-text">{parseInt(reportData?.data?.totalVotesCast) + parseInt(reportData?.data?.totalWrongVotes)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-secondary mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Total Rejected Votes</h5>
                        <p className="card-text">{reportData?.data?.totalWrongVotes}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Candidates Table */}
                <div className="card mb-4 shadow">
                  <div className="card-body">
                    <h5 className="card-title">Constituency and Party Votes ({type})</h5>
                    <table className="table table-striped table-bordered">
                      <thead className="thead-dark">
                        <tr>
                          <th>Constituency Name</th>
                          {uniqueParties?.map((partyName) => (
                            <th key={partyName}>{partyName}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {reportData?.data?.constituencies?.map((constituency) => (
                          <tr key={constituency.constituencyName}>
                            <td>{constituency.constituencyName}</td>
                            {uniqueParties?.map((partyName) => {
                              const party = constituency.parties.find((p) => p.partyName === partyName);
                              return (
                                <td key={partyName}>
                                  {party ? party.totalVotesSecured : 0}  - ({party ? party.votePercentage : 0})
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            <div className="card-body bg-white shadow my-3">
              {chartList?.length > 0 && (
                <Chart
                  options={chartOptions}
                  series={chartOptions.series}
                  type="bar"
                  height={320}
                />
              )}
            </div>
            <div className="card-body bg-white shadow">
              {list?.length > 0 && (
                <Chart
                  options={ConstchartOptions}
                  series={ConstchartOptions.series}
                  type="bar"
                  height={320}
                />
              )}
            </div>


          </div>
        </div>
      }
    </div>
  );
};

export default ResultDisplay;
