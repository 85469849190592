// Import the required hooks and components
import React, { useEffect, useRef, useState } from "react";
import { Search, Edit, Trash2 ,Upload} from "react-feather";
import { Link } from "react-router-dom";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { Modal,OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";

const AgentManagement = () => {
    const [role, setRole] = useState("");
    const [permissions, setPermissions] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [id, setId] = useState("");
    const [consId, setConsId] = useState("");
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [partyId, setPartyId] = useState("");
    const [selectedPollingStations, setSelectedPollingStations] = useState([]);
    const [pollingStationsOptions, setPollingStationsOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const fileInputRef = useRef(null); // Ref for file input

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setName("");
        setEmail("");
        setPassword("");
        setCountryCode("");
        setPhone("");
        setId("");
        setEdit(false);
        setSelectedPollingStations([]);
    };


    const { apiData: agentsData, reFetch } = useFetch("getAllAgents");
    const { apiData: getAllConsistuencies } = useFetch("getAllConsistuencies"); // Fetch polling stations data
    const { apiData: getAllParties } = useFetch("getAllParties"); // Fetch polling stations data
    
    // Assuming response.data.data.pollingstations is the data you get from the API
    const changeConsistuency = async (id) => {
      
        setConsId(id)
        try {
            let response = await PostApi("consistuencyPollingStationsForAgents", { constituencyId: id });
            console.log(response)
            if (response.data.status === "1") {
                const formattedOptions = response?.data?.data?.pollingstations.map(station => ({
                    value: station.id, // Ensure this is the correct unique identifier
                    label: station.name // Make sure this is the property you want to show in the Select
                }));
                setPollingStationsOptions(formattedOptions);
            } else {
                error_toaster(response.data.message);
            }
        } catch (error) {
            error_toaster(error.message);
        }
    };

    
    useEffect(() => {
        setRole(localStorage.getItem('role'));
        setPermissions(JSON.parse(localStorage.getItem('permissions')))
        if (agentsData?.data?.controllers) {
            const transformedData = agentsData?.data?.controllers.map((region) => {
                console.log(region);
              
                // Extract polling station names
                const pollingStationNames = region?.userpollingstations?.map(station => station?.pollingstation?.name).join(', ');
              
                return {
                  id: region.id,
                  name: region.name,
                  email: region.email,
                  partyId: region.partyId,
                  party: region?.party?.name,
                  countryCode: region.countryCode,
                  consistuencyId: region?.userconsistuencies[0]?.constituencyId,
                  constName: region?.userconsistuencies[0]?.constituency?.name,
                  phoneNum: region.phone,
                  phone: region.phone,
                  stations: pollingStationNames, // Updated to show names
                  role: "Agent",
                  pollingStations: region?.userpollingstations.map(station => {
                    console.log(station);
              
                    // Use polling station information directly from `userpollingstations`
                    const pollingStation = station?.pollingstation;
                    
                    return {
                      value: pollingStation?.id, // The station's ID
                      label: pollingStation?.name || 'Unknown' // Use the station name, fallback to 'Unknown' if not found
                    };
                  })
                };
              });
              
            setUserData(transformedData);
            setFilteredData(transformedData);
        }

    }, [agentsData, role, permissions]);
    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
    
        const filteredRecords = userData.filter(
          (user) =>
            user.name.toLowerCase().match(query) ||
            user.email.toLowerCase().match(query) ||
            user.phone.toString().match(query)
        );
        setFilteredData(filteredRecords);
      };
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.Name.length - b.Name.length,
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: (a, b) => a.Email.length - b.Email.length,
        },
        {
            title: "Phone No.",
            dataIndex: "phone",
        },
        {
            title: "Party",
            dataIndex: "party",
            sorter: (a, b) => a.Permissions.length - b.Permissions.length,
            render: (status) => <span className="btn btn-info btn-sm">{status}</span>,
        },
        {
            title: "Consistuency",
            dataIndex: "constName",
            sorter: (a, b) => a.Permissions.length - b.Permissions.length,
            render: (status) => <span className="btn btn-warning btn-sm">{status}</span>,
           
        },
        {
            title: "Polling Stations",
            dataIndex: "stations",
            sorter: (a, b) => a.Permissions.length - b.Permissions.length,
           
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <div className="input-block add-lists"></div>

                        {(role === "super admin" || (role === "user" && permissions.includes("edit_agent"))) && (
                            <Link className="me-2 p-2" to="#" onClick={() => editUser(record)}>
                                <Edit className="feather-edit" />
                            </Link>
                        )}

                        {(role === "super admin" || (role === "user" && permissions.includes("delete_agent"))) && (
                            <Link className="confirm-text p-2" to="#" onClick={() => deleteUser(record.id)}>
                                <Trash2 className="feather-trash-2" />
                            </Link>
                        )}
                    </div>

                </div>
            ),
        },
    ];
    const handleUploadClick = () => {
        fileInputRef.current.click(); // Trigger file input click
    };

    const addController = async () => {
        if (name == "" || email == "" || phone == "" || partyId == "" || password == "" || selectedPollingStations.length == 0) {
            error_toaster("Required missing fields");
            return false;
        }
        setLoading(true);
        let response = await PostApi("addAgent", {
            name: name,
            email: email,
            consId:consId,
            partyId: partyId,
            phone: phone,
            password: password,
            pollingStations: selectedPollingStations.map(station => station.value) // Add selected polling stations
        });
        if (response.data.status === "1") {
            setLoading(false);
            reFetch();
            setShow(false);
            success_toaster(response.data.message);
        } else {
            setLoading(false);
            error_toaster(response.data.message);
        }
    };

    const deleteUser = async (id) => {
        let response = await PostApi("deleteUser", { id: id });
        if (response.data.status === "1") {
            success_toaster(response.data.message);
            reFetch();
        } else {
            error_toaster(response.data.message);
        }
    };

    const editUser = (obj) => {
        console.log(obj)
        setEdit(true);
        setName(obj.name);
        setEmail(obj.email);
        setPhone(obj.phoneNum);
        setConsId(obj.consistuencyId);
        setPartyId(obj.partyId);
        setCountryCode(obj.countryCode);
        setPassword(obj.password);
        setId(obj.id);
        setSelectedPollingStations(obj.pollingStations); // Populate selected polling stations
        setShow(true);
    };

    const updateUser = async () => {
        try {
            setLoading(true);
            let response = await PostApi("updateAgent", {
                id: id,
                name: name,
                email: email,
                countryCode: countryCode,
                phone: phone,
                consId:consId,
                password: password,
                pollingStations: selectedPollingStations.map(station => station.value) // Update selected polling stations
            });
            if (response.data.status === "1") {
                reFetch();
                setLoading(false);
                setShow(false);
                success_toaster(response.data.message);
            } else {
                setLoading(false);
                error_toaster(response.data.message);
            }
        }
        catch (error) {
            error_toaster("Something went wrong");
            setLoading(false);
        }
    };
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            error_toaster("Please select a file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        setLoading(true);
        const response = await PostApi("addAgentsFromExcel", formData, true);
        console.log(response)
        if (response.data.status === "1") {
            reFetch();
            setLoading(false);
            success_toaster(response.data.message);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    return (
        <div className="page-wrapper">
            {
                loading ? <Loader /> : <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Agent Management</h4>
                                <h6>Manage Agents</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">

                            {
                                role == "super admin" ? <li>
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="upload-tooltip">Upload</Tooltip>}>
                                        <button className="btn btn-link" onClick={handleUploadClick}>
                                            <Upload />
                                        </button>
                                    </OverlayTrigger>
                                </li> : ""
                            }

                        </ul>
                        <div className="page-btn">
                            {
                                role == "admin" || role == "super admin" ? <button onClick={handleShow} className="btn btn-added">
                                    <PlusCircle className="me-2" />
                                    Add New
                                </button> : ""
                            }
                            {
                                (role == "user" && permissions.includes('add_agent')) && <button onClick={handleShow} className="btn btn-added">
                                    <PlusCircle className="me-2" />
                                    Add New
                                </button>
                            }
                        </div>
                    </div>

                    {/* Product list */}
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input value={searchQuery}
                      onChange={handleSearchChange} type="text" placeholder="Search" className="form-control form-control-sm formsearch" />
                                        <Link to="#" className="btn btn-searchset">
                                            <Search className="feather-search" />
                                        </Link>
                                    </div>
                                </div>

                            </div>
                            {/* Filter */}
                            <div className={`card`} id="filter_inputs">
                                <div className="card-body pb-0">
                                    <div className="row">

                                    </div>
                                </div>
                            </div>
                            <Table columns={columns} dataSource={filteredData} pagination={{ pageSize: 5 }} />
                        </div>
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                    />
                    {/* Add Admin Modal */}
                    <Modal show={show} onHide={handleClose} size="lg" className="modal-styled">
                        <Modal.Header closeButton className="bg-primary text-white">
                            <Modal.Title>{edit ? "Update Agent" : "Add Agent"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-4">
                            <form className="p-2">
                                <div className="form-group mb-3">
                                    <label className="form-label">Name</label>
                                    <input
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        type="text"
                                        className="form-control shadow-sm"
                                        placeholder="Enter Name"
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Email</label>
                                    <input
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        type="email"
                                        className="form-control shadow-sm"
                                        placeholder="Enter Email"
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Phone No.</label>
                                    <input
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        type="text"
                                        className="form-control shadow-sm"
                                        placeholder="Enter Phone Number"
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Password</label>
                                    <input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type="password"
                                        className="form-control shadow-sm"
                                        placeholder="*******"
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Select Party</label>
                                    <select
                                        value={partyId}
                                        onChange={(e) => setPartyId(e.target.value)}
                                        className="form-select shadow-sm"
                                    >
                                        <option value="">Select Party</option>
                                        {getAllParties?.data?.parties?.map((cons) => (
                                            <option key={cons.id} value={cons.id}>
                                                {cons?.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Select Consistuency</label>
                                    <select
                                    value={consId}
                                        onChange={(e) => changeConsistuency(e.target.value)}
                                        className="form-select shadow-sm"
                                    >
                                        <option value="">Select Consistuency</option>
                                        {getAllConsistuencies?.data?.consistuencies?.map((cons) => (
                                            <option key={cons.id} value={cons.id}>
                                                {cons?.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Polling Stations</label>
                                    <Select
                                        isMulti
                                        value={selectedPollingStations}
                                        onChange={setSelectedPollingStations}
                                        options={pollingStationsOptions}
                                        className="react-select-container shadow-sm"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                                <div className="d-flex justify-content-center gap-3 mt-4">
                                    <button type="button" className="btn btn-secondary shadow-sm" onClick={handleClose}>
                                        Close
                                    </button>
                                    {edit ? (
                                        <button onClick={updateUser} type="submit" className="btn btn-primary shadow-sm">
                                            Update
                                        </button>
                                    ) : (
                                        <button onClick={addController} type="submit" className="btn btn-primary shadow-sm">
                                            Save
                                        </button>
                                    )}
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>

                </div>
            }
        </div>
    );
};

export default AgentManagement;
