import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../../core/pagination/datatable";
import useFetch from "../../ApiClient/GetApi";

const AdminSkirtSummary = () => {
    const [partyId, setPartyId] = useState(null);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const storedPartyId = localStorage.getItem('partyId');
        if (storedPartyId) {
            setPartyId(storedPartyId);
        }
    }, []);

    const { apiData } = useFetch(`skirtSummaryForAdmin/${partyId}`);

    useEffect(() => {
        if (apiData && apiData.status === "1" && apiData.data) {
            // Extract and transform data for the table
            const formattedData = apiData.data.data.flatMap((region) => 
                region.constituencyResults.map((constituency) => ({
                    regionName: region.regionName || "N/A",
                    totalConstituencies: region.totalConstituencies || 0,
                    constituencyName: constituency.constituencyName || "N/A",
                    presidentialResults: constituency.presidentialResults || "N/A",
                    parliamentaryResults: constituency.parliamentaryResults || "N/A",
                }))
            );
            setTableData(formattedData);
        }
    }, [apiData]);

    const columns = [
        {
            title: "Region Name",
            dataIndex: "regionName",
            sorter: (a, b) => a.regionName.localeCompare(b.regionName),
        },
        {
            title: "Total Constituencies",
            dataIndex: "totalConstituencies",
            sorter: (a, b) => a.totalConstituencies - b.totalConstituencies,
        },
        {
            title: "Constituency Name",
            dataIndex: "constituencyName",
            sorter: (a, b) => a.constituencyName.localeCompare(b.constituencyName),
        },
        {
            title: "Presidential(%)",
            dataIndex: "presidentialResults",
        },
        {
            title: "Parliamentary(%)",
            dataIndex: "parliamentaryResults",
        },
    ];

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Skirt & Blouse Summary</h4>
                            <h6>Summary</h6>
                        </div>
                    </div>
                </div>

                {/* Table Section */}
                <div className="card mb-4 shadow my-3">
                    <div className="card-body">
                        <h5 className="card-title">Summary</h5>
                        {/* Render table with data */}
                        <Table
                            columns={columns}
                            dataSource={tableData} // Use the formatted data from state
                            pagination={{ pageSize: 5 }} // Optional pagination
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminSkirtSummary;
