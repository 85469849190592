import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import useFetch from "../../ApiClient/GetApi";
import Loader from "../../Utils/Loader";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster } from "../../Utils/Toaster";
import Chart from "react-apexcharts";
import { IMAGE_URL } from "../../Utils/urls";
import Table from "../../core/pagination/datatable";
import { toWords } from 'number-to-words';
import formatDate from "../../Utils/DateFormat";

ChartJS.register(ArcElement, ChartTooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const ControllerConstituencyReport = () => {
    const [partyId, setPartyId] = useState(null);
    const [userId, setUserId] = useState(null);
    const [constituency, setConstituency] = useState(null);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [list, setList] = useState([]);
    const [stationList, setStationList] = useState(null);
    const [pastResult, setPastResult] = useState([]);
    useEffect(() => {
        setPartyId(localStorage.getItem('partyId'));
        setUserId(localStorage.getItem('userId'));
    }, [partyId, userId]);


    const { apiData } = useFetch(`ControllerGetAllConsistuencies/${userId}`);
    console.log(apiData?.data)
    const consChange = async (id) => {
        setConstituency(id);
    };

    const submit = async () => {
        if (!type || !partyId || !constituency) {
            error_toaster("All Fields are required!");
            return false;
        }
        setLoading(true);
        let response = await PostApi("constituencyReport", { partyId, constituencyId: constituency, type });

        if (response.data?.status === "1") {
            const data = response.data.data;
            setReportData(data);

            setList(data?.data?.candidates);

            setLoading(false);
        } else {
            error_toaster("No Result found!");
            setLoading(false);
        }
    };

    const calculatePercentage = (securedVotes, totalVotes) => {
        return ((securedVotes / totalVotes) * 100).toFixed(2);
    };

    const [chartOptions, setChartOptions] = useState({
        series: [
            { name: "Total Votes Secured", data: [] }
        ],
        colors: ["#28C76F"],
        chart: {
            type: "bar",
            height: 320,
            stacked: false,
            zoom: { enabled: true },
        },
        responsive: [
            {
                breakpoint: 280,
                options: {
                    legend: {
                        position: "bottom",
                        offsetY: 0,
                    },
                },
            },
        ],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 4,
                borderRadiusApplication: "end",
                borderRadiusWhenStacked: "all",
                columnWidth: "20%",
            },
        },
        dataLabels: { enabled: false },
        yaxis: { min: 0, max: 3000, tickAmount: 5 },
        xaxis: { categories: [] },
        legend: { show: false },
        fill: { opacity: 1 },
    });

    useEffect(() => {
        if (list && list.length > 0) {
            const categories = [];
            const totalVotesSecured = [];

            list.forEach((candidate) => {
                categories.push(candidate.name);
                totalVotesSecured.push(candidate.totalVotesSecured);
            });

            setChartOptions((prevOptions) => ({
                ...prevOptions,
                series: [{ name: "Total Votes Secured", data: totalVotesSecured }],
                xaxis: { categories: categories.length > 0 ? categories : [""] },
                yaxis: { ...prevOptions.yaxis, max: Math.max(...totalVotesSecured) + 100 },
            }));
        }
    }, [list]);
    useEffect(() => {


        if (reportData) {
            const formattedData = reportData?.stationList?.map((party) => ({
                name: party.name,
                createdAt: formatDate(party.createdAt),
                code: party.code,
                constituency: party?.constituency?.name,
                totalVoters: party.totalVoters,
                totalVotesCast: party.totalVotesCast,
                status: party.polled

            }));
            setStationList(formattedData);
            const records = reportData?.pastResults?.map((party) => ({
                year: party.year,
                votes: party.votes,
                candidate: party.candidate,
                percentage: `${party.percentage}%` ?? "0",
                district: party.constituency?.district?.name,
                region: party.constituency?.district?.region?.name,
                createdAt: formatDate(party.createdAt)

            }));
            setPastResult(records)
        }
    }, [reportData]);
    const stationColumns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
        },
        {
            title: "P-Code",
            dataIndex: "code",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
        },

        {
            title: "Total Votes",
            dataIndex: "totalVoters",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
        },
        {
            title: "Total Votes Cast",
            dataIndex: "totalVotesCast",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
        },
        {
            title: "Created Date",
            dataIndex: "createdAt",
            sorter: (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate),
        },

    ];
    const pastColumns = [
        {
            title: "Year",
            dataIndex: "year",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
        },
        {
            title: "Candidate",
            dataIndex: "candidate",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
            render: (status) => <span className="btn btn-warning btn-sm" style={{ width: "120px" }}>{status}</span>,
        },
        {
            title: "No. of Votes",
            dataIndex: "votes",
        },
        {
            title: "% of Votes",
            dataIndex: "percentage",

        },

        {
            title: "District",
            dataIndex: "district",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
        },
        {
            title: "Region",
            dataIndex: "region",
            sorter: (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate),
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            sorter: (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate),
        },

    ];

    return (
        <div className="page-wrapper">
            {loading ? <Loader /> : (
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Reports & Analytics</h4>
                                <h6>Generate and View Reports</h6>
                            </div>
                        </div>
                    </div>

                    {/* Form for selecting region, district, etc */}
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="row">
                                {/* Form fields for Candidate Type, Party, and Constituency */}
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Candidate Type</label>
                                        <select name="candidateType" value={type} onChange={(e) => setType(e.target.value)} className="form-control">
                                            <option value="">Select Candidate Type</option>
                                            <option value="Parliamentary">Parliamentary</option>
                                            <option value="Presidential">Presidential</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Constituency</label>
                                        <select name="constituency" value={constituency} onChange={(e) => consChange(e.target.value)} className="form-control">
                                            <option value="">Select Constituency</option>
                                            {apiData?.data?.consistuencies?.map((cons) => (
                                                <option value={cons.id} key={cons.id}>{cons.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button onClick={submit} className="btn btn-primary mt-3">Submit</button>
                        </div>
                    </div>

                    {/* Display Report Data */}
                    {reportData && (
                        <>
                            {/* Display Constituency, Total Votes, and Wrong Votes */}
                            <div className="row mb-4">
                                <div className="col-md-3">
                                    <div className="card text-white bg-warning mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">Constituency</h5>
                                            <p className="card-text">{reportData?.constituencyData?.name || 'N/A'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-info mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">District</h5>
                                            <p className="card-text">{reportData?.constituencyData?.district?.name || 'N/A'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-success mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">Region</h5>
                                            <p className="card-text">{reportData?.constituencyData?.district?.region?.name || 'N/A'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-secondary mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">Total Votes Cast</h5>
                                            <p className="card-text">
                                                {Number(reportData?.data?.totalVotesCast ?? 0) + Number(reportData?.data?.totalWrongVotes ?? 0)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-warning mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">Total Rejected Votes</h5>
                                            <p className="card-text">{reportData?.data?.totalWrongVotes ?? 0}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-info mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">P.S with result</h5>
                                            <p className="card-text">
                                                {Number(reportData?.counts?.totalPollingStations ?? 0) - Number(reportData?.counts?.stationsWithNoResults ?? 0)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-success mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">P.S with no result</h5>
                                            <p className="card-text">{reportData?.counts?.stationsWithNoResults ?? 0}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-secondary mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">P.S %</h5>
                                            <p className="card-text">
                                                {(
                                                    ((Number(reportData?.counts?.totalPollingStations ?? 0) - Number(reportData?.counts?.stationsWithNoResults ?? 0)) /
                                                        Number(reportData?.counts?.totalPollingStations ?? 1)) * 100
                                                ).toFixed(2)}%
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    reportData?.data?.topTwoCandidates?.map((dat) => (
                                        <div className="col-md-6" key={dat.name}>
                                            <div className="card text-white bg-secondary mb-3">
                                                <div className="card-body">
                                                    <h5 className="card-title">{dat?.name}</h5>
                                                    <p className="card-text">
                                                        <small>{dat.party}</small> | 
                                                        <small> {dat.totalVotesSecured}</small> | 
                                                        <small> {dat.votePercentage}</small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                             
                            </div>

                            {/* Candidates Information Table */}
                            <div className="card mb-4 shadow">
                                <div className="card-body">
                                    <h5 className="card-title">Candidates Information</h5>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-bordered">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th>Candidate Name</th>
                                                    <th>Party</th>
                                                    <th>No. Station Won</th>
                                                    <th>No. Votes Secured (In Figures)</th>
                                                    <th>No. Votes Secured (In Words)</th>
                                                    <th>% of Votes Secured</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reportData.data.candidates?.map((candidateData) => (
                                                    <tr key={candidateData.name}>
                                                        <td>{candidateData.name}</td>
                                                        <td><div><img style={{ height: "40px", width: "40px", objectFit: "contain" }} src={IMAGE_URL + candidateData.image} /></div></td>
                                                        <td>{candidateData.pollingStationsWon}</td>
                                                        <td>{candidateData.totalVotesSecured}</td>
                                                        <td>{toWords(candidateData.totalVotesSecured)}</td>

                                                        <td>{calculatePercentage(candidateData.totalVotesSecured, reportData.data.totalVotesCast)}%</td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/* Chart Display */}
                            <div className="card-body bg-white shadow">
                                {list?.length > 0 && (
                                    <Chart
                                        options={chartOptions}
                                        series={chartOptions.series}
                                        type="bar"
                                        height={320}
                                    />
                                )}
                            </div>
                            {/* Display Station Information in Table */}
                            <div className="card mb-4 shadow my-2">
                                <div className="card-body">
                                    <h5 className="card-title">Polling Station Information</h5>
                                    <Table
                                        columns={stationColumns}
                                        dataSource={stationList}
                                        pagination={{ pageSize: 5 }}
                                        rowClassName={(record, index) => {
                                            console.log("Row Index:", index, "Record:", record); // Verify record and index
                                            return record?.status === "1" ? 'row-green' : '';
                                        }}
                                    />

                                </div>
                            </div>

                            <div className="card mb-4 shadow">
                                <div className="card-body">
                                    <h5 className="card-title">Past Results</h5>
                                    <Table
                                        columns={pastColumns}
                                        dataSource={pastResult}
                                        pagination={{ pageSize: 5 }}

                                    />

                                </div>
                            </div>



                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default ControllerConstituencyReport;
