import React, { useState } from "react";
import { Search, Trash2 } from "react-feather";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";
import useFetch from "../../ApiClient/GetApi";
import Table from "../../core/pagination/datatable"; // Importing Table


const Ballot = () => {
    const [show, setShow] = useState(false);
    const [ballotName, setBallotName] = useState("");
    const [image, setImage] = useState(null);
    const [description, setDescription] = useState("");
    const [questions, setQuestions] = useState([{ question: "", answers: [""] }]);
    const [loading, setLoading] = useState(false);
    const [showQuestionsModal, setShowQuestionsModal] = useState(false); // For showing questions modal
    const [selectedBallotQuestions, setSelectedBallotQuestions] = useState([]); // Selected ballot questions for modal
    // New states for checkboxes
    const [includeName, setIncludeName] = useState(false);
    const [includeGender, setIncludeGender] = useState(false);
    const [includeEmail, setIncludeEmail] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
const [editingBallotId, setEditingBallotId] = useState(null);
console.log(editingBallotId)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { apiData, reFetch } = useFetch("getAllBallots");
    console.log(JSON.stringify(apiData));

    // Handle description change with a 2000-word limit
    const handleDescriptionChange = (e) => {
        const desc = e.target.value;
        if (desc.split(/\s+/).length <= 2000) {
            setDescription(desc);
        }
    };

    // Handle image upload
    const handleImageUpload = (e) => {
        setImage(e.target.files[0]);
    };

    // Handle adding new question
    const addNewQuestion = () => {
        setQuestions([...questions, { question: "", answers: [""] }]);
    };

    // Handle adding a new answer to a specific question
    const addAnswer = (index) => {
        const newQuestions = [...questions];
        newQuestions[index].answers.push("");
        setQuestions(newQuestions);
    };

    // Handle question change
    const handleQuestionChange = (index, value) => {
        const newQuestions = [...questions];
        newQuestions[index].question = value;
        setQuestions(newQuestions);
    };

    // Handle answer change
    const handleAnswerChange = (qIndex, aIndex, value) => {
        const newQuestions = [...questions];
        newQuestions[qIndex].answers[aIndex] = value;
        setQuestions(newQuestions);
    };

    // Handle Save button click
    const handleSave = async () => {
        const formData = new FormData();

        // Append ballot name
        formData.append('ballotName', ballotName);

        // Append description
        formData.append('description', description);

        // Append userId from local storage
        formData.append('userId', localStorage.getItem("userId"));

        // Append image if available
        if (image) {
            formData.append('image', image);
        }

        // Append checkboxes
        formData.append('includeName', includeName);
        formData.append('includeGender', includeGender);
        formData.append('includeEmail', includeEmail);

        // Append questions and answers as a JSON string
        formData.append(
            'questions',
            JSON.stringify(questions.map((q) => ({
                question: q.question,
                answers: q.answers,
            })))
        );

        let response = await PostApi("addBallot", formData);
        console.log(response);
        if (response.data.status === "1") {
            success_toaster(response.data.message);
            reFetch();
            setLoading(false);
            setShow(false);
        } else {
            setLoading(false);
            error_toaster(response.data.message);
        }
    };
    const updateBallot = async () => {
        const formData = new FormData();

        // Append ballot name
        formData.append('ballotId', editingBallotId);
        formData.append('ballotName', ballotName);

        // Append description
        formData.append('description', description);

        // Append userId from local storage
        formData.append('userId', localStorage.getItem("userId"));

        // Append image if available
        if (image) {
            formData.append('image', image);
        }

        // Append checkboxes
        formData.append('includeName', includeName);
        formData.append('includeGender', includeGender);
        formData.append('includeEmail', includeEmail);

        // Append questions and answers as a JSON string
        formData.append(
            'questions',
            JSON.stringify(questions.map((q) => ({
                question: q.question,
                answers: q.answers,
            })))
        );

        let response = await PostApi("updateBallot", formData);
        console.log(response);
        if (response.data.status === "1") {
            success_toaster(response.data.message);
            reFetch();
            setLoading(false);
            setShow(false);
        } else {
            setLoading(false);
            error_toaster(response.data.message);
        }
    };

    const handleShowQuestions = (questions) => {
        setSelectedBallotQuestions(questions);
        setShowQuestionsModal(true);
    };
    const handleEdit = (ballot) => {
        console.log(JSON.stringify(ballot));
      
        setBallotName(ballot.ballotName || "");
        setDescription(ballot.description || "");
        setIncludeName(ballot.name || false);
        setIncludeGender(ballot.gender || false);
        setIncludeEmail(ballot.email || false);
      
        // Properly map the questions and answers to extract the answer strings
        const formattedQuestions = ballot.questions.map((q) => ({
          question: q.question,
          answers: q.answers.map((ans) => ans.answer) // Extract the answer string for each answer
        }));
      
        setQuestions(formattedQuestions); // Set the formatted questions in state
        setImage(null); // Reset image since we're not editing the image directly
        setIsEditing(true);
        setEditingBallotId(ballot.id); // Store the ID of the ballot being edited
        setShow(true); // Show the modal
      };
      
      

    const columns = [
        {
            title: "Ballot Name",
            dataIndex: "ballotName",
            key: "ballotName",
            render: (text) => text || "No Data",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            render: (text) => text || "No Data",
        },
        {
            title: "Include Name",
            dataIndex: "name",
            key: "name",
            render: (text) => text ? "Yes" : "No",
        },
        {
            title: "Include Gender",
            dataIndex: "gender",
            key: "gender",
            render: (text) => text ? "Yes" : "No",
        },
        {
            title: "Include Email",
            dataIndex: "email",
            key: "email",
            render: (text) => text ? "Yes" : "No",
        },
        {
            title: "Questions",
            key: "action",
            render: (_, record) => (
                <>
                    <button
                        className="btn btn-primary btn-sm"
                        onClick={() => handleShowQuestions(record.questions)}
                    >
                        View Questions
                    </button>

                </>
            ),
        },
        {
            title: "Actions",
            key: "action",
            render: (record) => (
              <>
                <button
                  className="btn btn-primary btn-sm me-2"
                  onClick={() => handleEdit(record)}
                >
                  Edit
                </button>
                <Link onClick={() => deleteBallot(record.id)} className="confirm-text p-2" to="#">
                  <Trash2 className="feather-trash-2" />
                </Link>
              </>
            ),
          },
    ];

    const deleteBallot = async (id) => {
       setLoading(true);
       let response = await PostApi("deleteBallot",{id:id});
       if(response.data.status == "1"){
        success_toaster(response.data.message);
        reFetch();
        setLoading(false);
       }
       else{
        error_toaster(response.data.message);
        setLoading(false);
       }
    }


    return (
        <div className="page-wrapper">
            {loading ? <Loader /> : (
                <div>
                    <div className="content">
                        <div className="page-header">
                            <div className="add-item d-flex">
                                <div className="page-title">
                                    <h4>Ballot Management</h4>
                                    <h6>Manage E-Ballots</h6>
                                </div>
                            </div>
                            <div className="page-btn">
                                <button onClick={handleShow} className="btn btn-added">
                                    <PlusCircle className="me-2" />
                                    Add New
                                </button>
                            </div>
                        </div>

                        {/* Ballot list */}
                        <div className="card table-list-card">
                            <div className="card-body">
                                <div className="table-top">
                                    <div className="search-set">
                                        <div className="search-input">
                                            <input type="text" placeholder="Search" className="form-control form-control-sm formsearch" />
                                            <Link to="#" className="btn btn-searchset">
                                                <Search className="feather-search" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <Table columns={columns} dataSource={apiData?.data?.data || []} rowKey="id" />
                            </div>
                        </div>
                    </div>

                    {/* Add Ballot Modal */}
                    <Modal show={show} size="xl" onHide={handleClose}>
                        <Modal.Header closeButton>
                        <Modal.Title>{isEditing ? "Edit Ballot" : "Add Ballot"}</Modal.Title>

                        </Modal.Header>
                        <Modal.Body>
                            <form className="p-2 shadow">
                                <div className="form-group my-2">
                                    <label>Ballot Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Ballot Name"
                                        value={ballotName}
                                        onChange={(e) => setBallotName(e.target.value)}
                                    />
                                </div>
                                <div className="form-group my-2">
                                    <label>Image Upload</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        onChange={handleImageUpload}
                                    />
                                </div>
                                <div className="form-group my-2">
                                    <label>Description (Max 2000 words)</label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        placeholder="Enter Description"
                                        value={description}
                                        onChange={handleDescriptionChange}
                                    />
                                </div>

                                {/* Checkboxes for additional info */}
                                <div className="form-group my-2">
                                    <label>Include the following fields:</label>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="includeName"
                                            checked={includeName}
                                            onChange={(e) => setIncludeName(e.target.checked)}
                                        />
                                        <label className="form-check-label" htmlFor="includeName">
                                            Name
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="includeGender"
                                            checked={includeGender}
                                            onChange={(e) => setIncludeGender(e.target.checked)}
                                        />
                                        <label className="form-check-label" htmlFor="includeGender">
                                            Gender
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="includeEmail"
                                            checked={includeEmail}
                                            onChange={(e) => setIncludeEmail(e.target.checked)}
                                        />
                                        <label className="form-check-label" htmlFor="includeEmail">
                                            Email
                                        </label>
                                    </div>
                                </div>

                                {/* Questions and Answers */}
                                {questions.map((q, qIndex) => (
                                    <div key={qIndex} className="mb-4 border p-2 my-2 shadow">
                                        <div className="form-group">
                                            <label>Question {qIndex + 1}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={`Enter Question ${qIndex + 1}`}
                                                value={q.question}
                                                onChange={(e) => handleQuestionChange(qIndex, e.target.value)}
                                            />
                                        </div>
                                        {q.answers.map((answer, aIndex) => (
                                            <div key={aIndex} className="form-group">
                                                <label>Answer {aIndex + 1}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={`Enter Answer ${aIndex + 1}`}
                                                    value={answer}
                                                    onChange={(e) => handleAnswerChange(qIndex, aIndex, e.target.value)}
                                                />
                                            </div>
                                        ))}
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-secondary my-2"
                                            onClick={() => addAnswer(qIndex)}
                                        >
                                            Add More Answers
                                        </button>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-sm btn-primary"
                                    onClick={addNewQuestion}
                                >
                                    Add Another Question
                                </button>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="d-flex justify-content-center w-100 gap-3">
                                <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                    Close
                                </button>
                              {
                                isEditing ?   <button type="button" className="btn btn-primary" onClick={updateBallot}>
                                Update
                            </button>:  <button type="button" className="btn btn-primary" onClick={handleSave}>
                                    Save
                                </button>
                              }
                            </div>
                        </Modal.Footer>
                    </Modal>
                    {/* Questions Modal */}
                    <Modal show={showQuestionsModal} onHide={() => setShowQuestionsModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Ballot Questions and Answers</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {selectedBallotQuestions.length > 0 ? (
                                selectedBallotQuestions.map((q, index) => (
                                    <div key={index} className="mb-4">
                                        <h5>{q.question}</h5>
                                        <ul>
                                            {q.answers.map((ans, i) => (
                                                <li key={i}>{ans.answer}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ))
                            ) : (
                                <p>No questions available for this ballot.</p>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={() => setShowQuestionsModal(false)}>
                                Close
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            )}
        </div>
    );
};

export default Ballot;
