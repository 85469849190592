import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import Chart from "react-apexcharts";
import useFetch from "../../ApiClient/GetApi";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { PostApi } from "../../ApiClient/PostApi";
import Loader from "../../Utils/Loader";
import { error_toaster } from "../../Utils/Toaster";

const ControllerDashboard = () => {
    const [userId, setUserId] = useState("");
    // const [stations, setStations] = useState([]);
    const [partyId, setPartyId] = useState("");
    const [candidates, setCandidates] = useState([]);
    const [constId, setConstId] = useState("");
    const [loading, setLoading] = useState(false);
    // const [pieGraphData, setPieGraphData] = useState({});
    // const [parliament, setParliament] = useState([]);

    useEffect(() => {
        setUserId(localStorage.getItem('userId'));
        setPartyId(localStorage.getItem('partyId'));
    }, []);

    const { apiData } = useFetch(`controllerDashboard/${userId}`);

    const selectedConsistuency = async (id) => {
        if (!partyId) {
            error_toaster("Please select Party");
            return false;
        }
        setLoading(true);
        setConstId(id);
        try {
            const response = await PostApi("graphData", { id: id, partyId: partyId });

            if (response?.data?.status === "1") {
                // setStations(response?.data?.data?.stations || []);
                setCandidates(response?.data?.data?.chartData?.parties || []);
                // setPieGraphData(response?.data?.data?.pieGraph || {});
                // setParliament(response?.data?.data?.chartData?.parliamentaryPollingStatus || []);
            } else {
                error_toaster("Error fetching graph data.");
            }
        } catch (error) {
            console.error("Error fetching constituency data:", error);
        }
        setLoading(false);
    };

    // Bar Chart Configuration
    const [chartOptions, setChartOptions] = useState({
        series: [
            { name: "Parliamentary", data: [] },
            { name: "Presidential", data: [] },
        ],
        colors: ["#28C76F", "#EA5455"],
        chart: {
            type: "bar",
            height: 320,
            stacked: true,
            zoom: { enabled: true },
        },
        responsive: [
            {
                breakpoint: 280,
                options: {
                    legend: {
                        position: "bottom",
                        offsetY: 0,
                    },
                },
            },
        ],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 4,
                borderRadiusApplication: "end",
                borderRadiusWhenStacked: "all",
                columnWidth: "20%",
            },
        },
        dataLabels: { enabled: false },
        yaxis: { min: 0, max: 3000, tickAmount: 5 },
        xaxis: { categories: [] },
        legend: { show: true },
        fill: { opacity: 1 },
    });

    // Update Bar Chart Data
    useEffect(() => {
        if (Array.isArray(candidates) && candidates?.length > 0) {
            const categories = [];
            const parliamentaryVotes = [];
            const presidentialVotes = [];
            let maxVote = 300;

            candidates.forEach((party) => {
                categories.push(party.name);

                // Extract votes for each type
                const presidentialVote = party.types?.find((vote) => vote.type === "Presidential")?.votes || 0;
                const parliamentaryVote = party.types?.find((vote) => vote.type === "Parliamentary")?.votes || 0;

                presidentialVotes.push(presidentialVote);
                parliamentaryVotes.push(parliamentaryVote);

                // Update maximum vote value for y-axis scaling
                maxVote = Math.max(maxVote, presidentialVote, parliamentaryVote);
            });

            // Update chart options
            setChartOptions((prevOptions) => ({
                ...prevOptions,
                series: [
                    { name: "Parliamentary", data: parliamentaryVotes },
                    { name: "Presidential", data: presidentialVotes },
                ],
                xaxis: { categories: categories?.length > 0 ? categories : [""] },
                yaxis: { ...prevOptions.yaxis, max: maxVote + 100 },
            }));
        }
    }, [candidates]);


    return (
        <div className="page-wrapper">
            {loading ? (
                <Loader />
            ) : (
                <div className="content">
                    {/* Dashboard Widgets */}
                    <div className="row">
                        <div className="col-xl-3 col-sm-6 col-12 d-flex">
                            <div className="dash-widget w-100">
                                <div className="dash-widgetimg">
                                    <span>
                                        <ImageWithBasePath src="assets/img/icons/dash1.svg" alt="img" />
                                    </span>
                                </div>
                                <div className="dash-widgetcontent">
                                    <h5>
                                        <CountUp start={0} end={apiData?.data?.constituencyCount || 0} duration={3} />
                                    </h5>
                                    <h6>Constituencies</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12 d-flex">
                            <div className="dash-widget dash1 w-100">
                                <div className="dash-widgetimg">
                                    <span>
                                        <ImageWithBasePath src="assets/img/icons/dash2.svg" alt="img" />
                                    </span>
                                </div>
                                <div className="dash-widgetcontent">
                                    <h5>
                                        <CountUp start={0} end={apiData?.data?.pollingstationCount || 0} duration={3} />
                                    </h5>
                                    <h6>Polling Stations</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12 d-flex">
                            <div className="dash-widget dash2 w-100">
                                <div className="dash-widgetimg">
                                    <span>
                                        <ImageWithBasePath src="assets/img/icons/dash3.svg" alt="img" />
                                    </span>
                                </div>
                                <div className="dash-widgetcontent">
                                    <h5>
                                        <CountUp start={0} end={apiData?.data?.totalVoters || 0} duration={3} />
                                    </h5>
                                    <h6>Total Voters</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12 d-flex">
                            <div className="dash-widget dash3 w-100">
                                <div className="dash-widgetimg">
                                    <span>
                                        <ImageWithBasePath src="assets/img/icons/dash4.svg" alt="img" />
                                    </span>
                                </div>
                                <div className="dash-widgetcontent">
                                    <h5>
                                        <CountUp start={0} end={apiData?.data?.agents || 0} duration={3} />
                                    </h5>
                                    <h6>Agents</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Bar Chart */}
                    <div className="row">
                        <div className="col-xl-12 col-sm-12 col-12 d-flex">
                            <div className="card flex-fill">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5 className="card-title mb-0">Constituency Report</h5>
                                    <div className="graph-sets">
                                        <ul className="mb-0">
                                            <li><span>Parliamentary</span></li>
                                            <li><span>Presidential</span></li>
                                        </ul>
                                        <div className="dropdown dropdown-wraper">
                                            <select defaultValue={constId} onChange={(e) => selectedConsistuency(e.target.value)} className="form-control">
                                                <option>Select Constituency</option>
                                                {apiData?.data?.constituencies?.map((cons) => (
                                                    <option key={cons.id} value={cons.constituency?.id}>{cons?.constituency?.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Chart options={chartOptions} series={chartOptions.series} type="bar" height={320} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Pie Charts */}
                    
                </div>
            )}
        </div>
    );
};

export default ControllerDashboard;
