import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../../core/pagination/datatable";
import useFetch from "../../ApiClient/GetApi";

const AdminNeckReport = () => {
    const [partyId, setPartyId] = useState(null);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const storedPartyId = localStorage.getItem('partyId');
        if (storedPartyId) {
            setPartyId(storedPartyId);
        }
    }, []);

    const { apiData } = useFetch(`neckReportForAdmin/${partyId}`);

    useEffect(() => {
        if (apiData && apiData.status === "1" && Array.isArray(apiData.data)) {
            // Transform and format data for the table
            const formattedData = apiData.data.map((item) => ({
                regionName: item.region || "N/A",
                totalConstituencies: item.constituency || "N/A",
                constituencyName: item.constituency || "N/A",
                winner: `${item.winnerCandidate || "N/A"} (${item.winnerParty || "N/A"})`,
                winnerPercentage: item.winnerVotePercentage || "0%",
                runnerUp: `${item.runnerUpCandidate || "N/A"} (${item.runnerUpParty || "N/A"})`,
                runnerUpPercentage: item.runnerUpVotePercentage || "0%",
                voteDifference: item.voteDifference || 0,
            }));
            setTableData(formattedData);
        }
    }, [apiData]);

    const columns = [
        {
            title: "Region Name",
            dataIndex: "regionName",
            sorter: (a, b) => a.regionName.localeCompare(b.regionName),
        },
        {
            title: "Constituency Name",
            dataIndex: "constituencyName",
            sorter: (a, b) => a.constituencyName.localeCompare(b.constituencyName),
        },
        {
            title: "Winner",
            dataIndex: "winner",
        },
        {
            title: "Winner (%)",
            dataIndex: "winnerPercentage",
        },
        {
            title: "Runner-Up",
            dataIndex: "runnerUp",
        },
        {
            title: "Runner-Up (%)",
            dataIndex: "runnerUpPercentage",
        },
        {
            title: "Vote Difference",
            dataIndex: "voteDifference",
            sorter: (a, b) => a.voteDifference - b.voteDifference,
        },
    ];

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Neck to Neck Report</h4>
                        </div>
                    </div>
                </div>

                {/* Table Section */}
                <div className="card mb-4 shadow my-3">
                    <div className="card-body">
                        <h5 className="card-title">Summary</h5>
                        {/* Render table with data */}
                        <Table
                            columns={columns}
                            dataSource={tableData} // Use the formatted data from state
                            pagination={{ pageSize: 5 }} // Optional pagination
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminNeckReport;
