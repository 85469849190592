import React, { useEffect, useState, useRef } from "react";
import {  Search } from "react-feather";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../../core/pagination/datatable";
import useFetch from "../../ApiClient/GetApi";
import formatDate from "../../Utils/DateFormat";

const ControllerConsistuency = () => {

    const [constituencyData, setConstituencyData] = useState([]);
    const fileInputRef = useRef(null); // Ref for file input
    const { apiData } = useFetch(`ControllerGetAllConsistuencies/${localStorage.getItem('userId')}`);
    
    console.log(apiData)
    useEffect(() => {
        if (apiData?.data?.consistuencies) {
            let transformedData = apiData.data.consistuencies.map((constituency) => ({
              key: constituency?.id,
              areaName: constituency?.areaName ?? '',
              areaCode: constituency?.areaCode ?? '',
              ConstituencyName: constituency?.name?.trim() ?? '',
              totalVoters: constituency?.totalVoters ?? 0,
              totalPollingStations: constituency?.totalPollingStations ?? 0,
              CreatedDate: formatDate(constituency?.createdAt),
            }));
            
            setConstituencyData(transformedData);
          }
          
    }, [apiData]);

    const columns = [
        {
            title: "Constituency Name",
            dataIndex: "ConstituencyName",
            sorter: (a, b) => a.ConstituencyName.length - b.ConstituencyName.length,
            render: (status) => <span className="btn btn-warning btn-sm" style={{ width: "140px" }}>{status}</span>,
        },
        {
            title: "EA Name",
            dataIndex: "areaName",
            sorter: (a, b) => a.ConstituencyName.length - b.ConstituencyName.length,
            render: (status) => <span className="btn btn-info btn-sm" style={{ width: "120px" }}>{status}</span>,
        },
        {
            title: "EA Code",
            dataIndex: "areaCode",
            sorter: (a, b) => a.ConstituencyName.length - b.ConstituencyName.length,
            render: (status) => <span className="btn btn-success btn-sm" style={{ width: "120px" }}>{status}</span>,
        },
        {
            title: "Total Voters",
            dataIndex: "totalVoters",
            sorter: (a, b) => a.DistrictName.length - b.DistrictName.length,
        },
        {
            title: "Total Polling Stations",
            dataIndex: "totalPollingStations",
            sorter: (a, b) => a.RegionName.length - b.RegionName.length,
        },
        {
            title: "Created Date",
            dataIndex: "CreatedDate",
            sorter: (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate),
        },
        
    ];

    return (
        <div className="page-wrapper">
           
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Constituency Management</h4>
                                <h6>Manage Constituencies</h6>
                            </div>
                        </div>
                       
                       
                    </div>

                    {/* Constituency list */}
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="form-control form-control-sm formsearch"
                                        />
                                        <Link to="#" className="btn btn-searchset">
                                            <Search className="feather-search" />
                                        </Link>
                                    </div>
                                </div>
                               
                            </div>
                            {/* Filter */}
                            <div className={`card`} id="filter_inputs">
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">
                                                <div className="col-lg col-sm-6 col-12">
                            
                                                </div>
                                                <div className="col-lg col-sm-6 col-12">
                                                    <div className="form-group">
                                                        <input type="text" placeholder="Enter Constituency Name" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-lg col-sm-6 col-12">
                                                    <div className="form-group">
                                                        <input type="text" placeholder="Enter District Name" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                                                    <div className="form-group">
                                                        <Link className="btn btn-filters ms-auto" to="#">
                                                            <img src="assets/img/icons/searchwhites.svg" alt="img" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Constituency Table */}
                            <div className="table-responsive">
                                <Table
                                    className="table  table-hover table-center mb-0 datatable"
                                    columns={columns}
                                    dataSource={constituencyData}
                                    rowKey={(record) => record.key}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Hidden file input */}
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept=".xlsx, .xls"
                       
                    />
                   
                </div>
           
        </div>
    );
};

export default ControllerConsistuency;
