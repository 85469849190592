import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import useFetch from "../../ApiClient/GetApi";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { PostApi } from "../../ApiClient/PostApi";
import { IMAGE_URL } from "../../Utils/urls";
import { error_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";
import Chart from "react-apexcharts";
import { toWords } from 'number-to-words';

const ControllerResultDisplay = () => {
  const [loading, setLoading] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedConstituency, setSelectedConstituency] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const [partyId, setPartyId] = useState(null);
  const [text, setText] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [constituencies, setConstituencies] = useState([]);
  const [pollingStations, setPollingStations] = useState([]);
  const [type, setType] = useState(null);
  const [reportData, setReportData] = useState(null); // State to hold the API response data
  const [chartList, setChartList] = useState([]);
  const [list, setList] = useState([]);

  useEffect(()=>{
    setPartyId(localStorage.getItem("partyId"))
  },[partyId])
  const getAllRegions = useFetch("getAllRegions");
  const handleRegionChange = async (regionId) => {
    setSelectedRegion(regionId);
    setSelectedDistrict(null);
    setSelectedConstituency(null);
    setPollingStations([]);

    try {
      const response = await PostApi("regionDistricts", { regionId });
      setDistricts(response.data.data.districts || []);
    } catch (error) {
      console.error("Error fetching districts:", error);
    }
  };

  const handleDistrictChange = async (districtId) => {
    setSelectedDistrict(districtId);
    setSelectedConstituency(null);
    setPollingStations([]);

    try {
      const response = await PostApi("districtConsistuency", { districtId });
      setConstituencies(response.data.data.consistuencies || []);
    } catch (error) {
      console.error("Error fetching constituencies:", error);
    }
  };

  const handleConstituencyChange = async (constituencyId) => {
    setSelectedConstituency(constituencyId);
    setPollingStations([]);

    try {
      const response = await PostApi("consistuencyPollingStationsForRecords", { constituencyId });
      setPollingStations(response.data.data.pollingstations || []);
    } catch (error) {
      console.error("Error fetching polling stations:", error);
    }
  };
  const uniqueParties = reportData?.data?.constituencies?.reduce((acc, cons) => {
    cons.parties.forEach((party) => {
      if (!acc.includes(party.partyName)) {
        acc.push(party.partyName);
      }
    });
    return acc;
  }, []);
  const submit = async () => {
    try {
      setLoading(true)
      const response = await PostApi("resultDisplayReport", {
        regionId: selectedRegion,
        districtId: selectedDistrict,
        constituencyId: selectedConstituency,
        pollingStationId: selectedStation,
        type: type,
        partyId
      });
      // console.log(response?.data?.data?.data)
      if (response.data.message == "pollingstation") {
        setText(response.data.message);
        setReportData(response.data.data); // Set the fetched report data
      }
      if (response.data.message == "constituency") {
        setText(response.data.message);
        setReportData(response.data.data); // Set the fetched report data
        setList(response?.data?.data?.data?.candidates);
      }
      if (response.data.message == "district") {
        setText(response.data.message);
        setReportData(response.data.data); // Set the fetched report data
      }
      if (response.data.message == "region") {
        setText(response.data.message);
        setReportData(response.data.data); // Set the fetched report data
      }
      if (response.data.message == "national") {
        setText(response.data.message);
        setReportData(response.data.data); // Set the fetched report data
      }
      setChartList(response?.data?.data?.chartData)

      setLoading(false)
    } catch (error) {
      error_toaster(error.message);
      setLoading(false);
    }
  };
  const getWinningCandidate = () => {
    if (!reportData || !reportData.pollingStationCandidates) return null;
    const winningCandidate = reportData.pollingStationCandidates.reduce((max, candidate) => (parseInt(candidate.voteSecured) > parseInt(max.voteSecured) ? candidate : max), reportData.pollingStationCandidates[0]);
    return winningCandidate;
  };

  const winningCandidate = getWinningCandidate();
  const calculateValidVotes = () => {
    if (!reportData || !reportData?.data?.data?.pollingStationCandidates) return 0;

    return reportData?.data?.data.pollingStationCandidates.reduce((sum, candidateData) => {
      const { voteSecured } = candidateData;
      const secured = voteSecured !== null ? parseInt(voteSecured) : 0;
      return sum + secured;
    }, 0);
  };
  console.log(reportData?.data);
  const isArrayAndNotEmpty = (value) => Array.isArray(value) && value.length > 0;

  const [chartOptions, setChartOptions] = useState({
    series: [
      { name: "Parliamentary", data: [] },
      { name: "Presidential", data: [] },
    ],
    colors: ["#28C76F", "#EA5455"],
    chart: {
      type: "bar",
      height: 320,
      stacked: true,
      zoom: { enabled: true },
    },
    responsive: [
      {
        breakpoint: 280,
        options: {
          legend: {
            position: "bottom",
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
        columnWidth: "20%",
      },
    },
    dataLabels: { enabled: false },
    yaxis: { min: 0, max: 300, tickAmount: 5 },
    xaxis: { categories: [] },
    legend: { show: false },
    fill: { opacity: 1 },
  });

  useEffect(() => {
    if (chartList && chartList.length > 0) {
      const categories = []; // To store party names
      const seriesData = []; // To store votes secured for each party

      let maxVote = 300;

      // Iterate through the chartList to extract party names and their votes secured
      chartList.forEach((party) => {
        categories.push(party.partyName);
        const votesSecured = parseInt(party.totalVotesSecured, 10) || 0;
        seriesData.push(votesSecured);
        maxVote = Math.max(maxVote, votesSecured);
      });

      // Create a single series array with the party names and vote counts
      const seriesArray = [
        {
          name: "Total Votes Secured", // This is the label for the series in the chart
          data: seriesData, // Set the data as the votes secured
        },
      ];

      // Update the chart options with the new series and categories
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        series: seriesArray, // Use the constructed series array
        xaxis: { categories: categories.length > 0 ? categories : [""] }, // Set the categories as the party names
        yaxis: { ...prevOptions.yaxis, max: maxVote + 50 }, // Adjust the y-axis max value
      }));
    }
  }, [chartList]);


  const [ConstchartOptions, setConstChartOptions] = useState({
    series: [
      { name: "Total Votes Secured", data: [] }
    ],
    colors: ["#28C76F"],
    chart: {
      type: "bar",
      height: 320,
      stacked: false,
      zoom: { enabled: true },
    },
    responsive: [
      {
        breakpoint: 280,
        options: {
          legend: {
            position: "bottom",
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
        columnWidth: "20%",
      },
    },
    dataLabels: { enabled: false },
    yaxis: { min: 0, max: 3000, tickAmount: 5 },
    xaxis: { categories: [] },
    legend: { show: false },
    fill: { opacity: 1 },
  });

  useEffect(() => {
    if (list && list.length > 0) {
      const categories = [];
      const totalVotesSecured = [];

      list.forEach((candidate) => {
        categories.push(candidate.name);
        totalVotesSecured.push(candidate.totalVotesSecured);
      });

      setConstChartOptions((prevOptions) => ({
        ...prevOptions,
        series: [{ name: "Total Votes Secured", data: totalVotesSecured }],
        xaxis: { categories: categories.length > 0 ? categories : [""] },
        yaxis: { ...prevOptions.yaxis, max: Math.max(...totalVotesSecured) + 100 },
      }));
    }
  }, [list]);


  return (
    <div className="page-wrapper">
      {
        loading ? <Loader /> : <div>
          <ToastContainer />
        <div className="content">
            <div className="page-header">
              <div className="add-item d-flex">
                <div className="page-title">
                  <h4>Results Display</h4>
                  <h6>View and Analyze Results</h6>
                </div>
              </div>
            </div>

            {/* Select Inputs for Regions, Districts, Constituencies, and Polling Stations */}
            <div className="row mb-4">
              <div className="col-md-3">
                <div className="form-group">
                  <label>Candidate Type</label>
                  <select
                    name="candidateType"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    className="form-control"
                  >
                    <option value="">Select Candidate Type</option>
                    <option value="Parliamentary">Parliamentary</option>
                    <option value="Presidential">Presidential</option>
                  </select>
                </div>
              </div>
             
              <div className="col-md-3">
                <label>Region</label>
                <select className="form-control" value={selectedRegion || ""} onChange={(e) => handleRegionChange(e.target.value)}>
                  <option value="">Select Region</option>
                  {getAllRegions?.apiData?.data?.regions?.map((region) => (
                    <option key={region.id} value={region.id}>
                      {region.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-3">
                <label>District</label>
                <select className="form-control" value={selectedDistrict || ""} onChange={(e) => handleDistrictChange(e.target.value)} disabled={!selectedRegion}>
                  <option value="">Select District</option>
                  {districts.map((district) => (
                    <option key={district.id} value={district.id}>
                      {district.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-3">
                <label>Constituency</label>
                <select className="form-control" value={selectedConstituency || ""} onChange={(e) => handleConstituencyChange(e.target.value)} disabled={!selectedDistrict}>
                  <option value="">Select Constituency</option>
                  {constituencies.map((constituency) => (
                    <option key={constituency.id} value={constituency.id}>
                      {constituency.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-3">
                <label>Polling Station</label>
                <select onChange={(e) => setSelectedStation(e.target.value)} defaultValue={selectedStation} className="form-control" disabled={!selectedConstituency}>
                  <option value="">Select Polling Station</option>
                  {pollingStations.map((station) => (
                    <option key={station.id} value={station.id}>
                      {station.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
                <label htmlFor=""></label>
                <div>
                  <button onClick={submit} className="btn btn-warning">Submit</button>
                </div>
              </div>
            </div>

            {
              reportData && text == "national" && (
                <div>
                  <div className="row mb-4">
                    {/* Region Count */}
                    <div className="col-md-3">
                      <div className="card text-white bg-primary mb-3">
                        <div className="card-body">
                          <h5 className="card-title">No. of Regions</h5>
                          <p className="card-text">{reportData?.regionCount || 0}</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="card text-white bg-success mb-3">
                        <div className="card-body">
                          <h5 className="card-title">No. of Constituencies</h5>
                          <p className="card-text">{reportData?.constituencyCount || 0}</p>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3">
                      <div className="card text-white bg-secondary mb-3">
                        <div className="card-body">
                          <h5 className="card-title">Total Votes Cast</h5>
                          <p className="card-text">{parseInt(reportData?.data?.totalVotesCast) + parseInt(reportData?.data?.totalWrongVotes)}</p>
                        </div>
                      </div>
                    </div>

                    {/* Total Rejected Votes */}

                    <div className="col-md-3">
                      <div className="card text-white bg-warning mb-3">
                        <div className="card-body">
                          <h5 className="card-title">Total Rejected Votes</h5>
                          <p className="card-text">{reportData?.data?.totalWrongVotes || 0}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card text-white bg-info mb-3">
                        <div className="card-body">
                          <h5 className="card-title">Total Polling Stations</h5>
                          <p className="card-text">{reportData?.counts?.totalPollingStations}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card text-white bg-success mb-3">
                        <div className="card-body">
                          <h5 className="card-title">P.S with results</h5>
                          <p className="card-text">{Number(reportData?.counts?.totalPollingStations ?? 0) - Number(reportData?.counts?.stationsWithNoResults ?? 0)}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card text-white bg-secondary mb-3">
                        <div className="card-body">
                          <h5 className="card-title">P.S with no results</h5>
                          <p className="card-text">{reportData?.counts?.stationsWithNoResults ?? 0}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card text-white bg-warning mb-3">
                        <div className="card-body">
                          <h5 className="card-title">P.S %</h5>
                          <p className="card-text"> {(
                            ((Number(reportData?.counts?.totalPollingStations ?? 0) - Number(reportData?.counts?.stationsWithNoResults ?? 0)) /
                              Number(reportData?.counts?.totalPollingStations ?? 1)) * 100
                          ).toFixed(2)}%</p>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="card mb-4 shadow">
                    <div className="card-body">
                      <h5 className="card-title">{type === "Presidential" ? "Candidates Data" : "Parties Data"}</h5>
                      <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                          <tr>
                            {type === "Presidential" ? (
                              <>
                                <th>Candidate Name</th>
                                <th>Party Name</th>
                                <th>Total Votes Secured</th>
                                <th>Constituencies Won</th>
                                <th>Vote Percentage</th>
                              </>
                            ) : (
                              <>
                                <th>Party Name</th>
                                <th>Total Votes Secured</th>
                                <th>Constituencies Won</th>
                                <th>Constituencies Lost</th>
                                <th>Vote Percentage</th>
                              </>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {type === "Presidential" ? (
                            isArrayAndNotEmpty(reportData?.data?.candidates) ? (
                              reportData.data.candidates.map((candidate) => (
                                <tr key={candidate?.candidateName}>
                                  <td>{candidate?.candidateName || "N/A"}</td>
                                  <td>{candidate?.partyName || "N/A"}</td>
                                  <td>{candidate?.totalVotesSecured || 0}</td>
                                  <td>{candidate?.constituenciesWon || 0}</td>
                                  <td>{candidate?.votePercentage || "0.00%"}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5">No data available</td>
                              </tr>
                            )
                          ) : (
                            // Check the correct path for partyData
                            isArrayAndNotEmpty(reportData?.data?.partyData) ? (
                              reportData.data.partyData?.map((party) => (
                                <tr key={party?.partyName}>
                                  <td>{party?.partyName || "N/A"}</td>
                                  <td>{party?.totalVotesSecured || 0}</td>
                                  <td>{party?.constituenciesWon || 0}</td>
                                  <td>{party?.constituenciesLost || 0}</td>
                                  <td>{party?.votePercentage || "0.00%"}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5">No data available</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>


                  </div>

                </div>
              )
            }
            {reportData && text === "pollingstation" && (
              <>
                {/* Polling Station Summary Section */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <div className="card text-white bg-warning mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Polling Station</h5>
                        <p className="card-text">{reportData?.data?.data?.dd?.name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-info mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Total Voters</h5>
                        <p className="card-text">{reportData?.data?.data?.dd?.totalVoters}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-secondary mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Constituency</h5>
                        <p className="card-text">{reportData?.data?.data?.dd?.constituency?.name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-success mb-3">
                      <div className="card-body">
                        <h5 className="card-title">District</h5>
                        <p className="card-text">{reportData?.data?.data?.dd?.constituency?.district?.name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-warning mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Region</h5>
                        <p className="card-text">{reportData?.data?.data?.dd?.constituency?.district?.region?.name}</p>
                      </div>
                    </div>
                  </div>

                  {/* Winner Candidate Section */}
                  {winningCandidate && (
                    <>
                      <div className="col-md-3">
                        <div className="card text-white bg-info mb-3">
                          <div className="card-body">
                            <h5 className="card-title">Winner Candidate</h5>
                            <p className="card-text">{winningCandidate.candidate.name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="card text-white bg-secondary mb-3">
                          <div className="card-body">
                            <h5 className="card-title">Winner Votes</h5>
                            <p className="card-text">{winningCandidate.voteSecured}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="card text-white bg-success mb-3">
                          <div className="card-body">
                            <h5 className="card-title">Winner Party</h5>
                            <p className="card-text">{winningCandidate.candidate.party.name}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {/* Polling Station Candidates Table */}
                <div>
                  <div className="card shadow">
                    <div className="card-body">
                      <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                          <tr>
                            <th>Candidate Name</th>
                            <th>Party</th>
                            <th>No. Votes Secured (In Figures)</th>
                            <th>No. Votes Secured (In Words)</th>

                          </tr>
                        </thead>
                        <tbody>
                          {reportData?.data?.data?.pollingStationCandidates?.map((candidateData) => {
                            const { candidate, voteSecured } = candidateData;
                            return (
                              <tr key={candidate.id}>
                                <td>{candidate.name}</td>
                                <td>
                                  <div>
                                    <img
                                      src={IMAGE_URL + candidate?.party?.image}
                                      style={{ height: "40px", width: "40px", objectFit: "contain" }}
                                      alt={candidate?.party?.name}
                                    />
                                  </div>
                                </td>
                                <td>{voteSecured ?? 0}</td>
                                <td>{toWords(voteSecured ?? 0)}</td>

                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan="2">Total Valid Ballot</td>
                            <td>{calculateValidVotes()}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td colSpan="2">Total Rejected Ballot</td>
                            <td>{reportData?.data?.data?.resultData?.wrongVotes}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td colSpan="2">Total Ballot Cast</td>
                            <td>{parseInt(calculateValidVotes()) + parseInt(reportData?.data?.data?.resultData?.wrongVotes ?? 0)}</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}

            {reportData && text == "constituency" && (
              <div className="report-section">
                <div className="row">

                <div className="col-md-3">
                    <div className="card text-white bg-secondary">
                      <div className="card-body">
                        <h5 className="card-title">Total Polling Stations</h5>
                        <p className="card-text">{reportData?.counts?.totalPollingStations}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-info">
                      <div className="card-body">
                        <h5 className="card-title">P.S with Results</h5>
                        <p className="card-text">{Number(reportData?.counts?.totalPollingStations ?? 0) - Number(reportData?.counts?.stationsWithNoResults ?? 0)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-success">
                      <div className="card-body">
                        <h5 className="card-title">P.S without Results</h5>
                        <p className="card-text">{reportData?.counts?.stationsWithNoResults ?? 0}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-warning">
                      <div className="card-body">
                        <h5 className="card-title">% of P.S</h5>
                        <p className="card-text"> {(
                            ((Number(reportData?.counts?.totalPollingStations ?? 0) - Number(reportData?.counts?.stationsWithNoResults ?? 0)) /
                              Number(reportData?.counts?.totalPollingStations ?? 1)) * 100
                          ).toFixed(2)}%</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Candidates Table */}
                <div className="card mb-4 shadow">
                  <div className="card-body">
                    <h5 className="card-title">Candidates Information</h5>
                    <div className="table-responsive"></div>
                    <table className="table table-striped table-bordered mt-4">
                      <thead className="thead-dark">
                        <tr>
                          <th>Candidate Name</th>
                          <th>Party</th>

                          <th>No. Votes Secured (In Figures)</th>
                          <th>No. Votes Secured (In Words)</th>
                          <th>% of Votes Secured</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reportData.data.candidates.map((candidate) => (
                          <tr key={candidate.id}>
                            <td>{candidate?.name}</td>
                            <td>
                              <div>
                                <img
                                  style={{ height: "40px", width: "40px", objectFit: "contain" }}
                                  src={IMAGE_URL + candidate.party.image}
                                  alt={candidate?.party?.name}
                                />
                              </div>
                            </td>

                            <td>{candidate.totalVotesSecured}</td>
                            <td>{toWords(candidate.totalVotesSecured)}</td>
                            <td>{candidate.votePercentage}%</td>
                          </tr>
                        ))}
                        {/* Display total valid votes, wrong votes, and total vote cast */}
                        <tr>
                          <td colSpan="3" className="text-right"><strong>Total Valid Votes</strong></td>
                          <td><strong>{reportData.data.candidates.reduce((acc, candidate) => acc + candidate.totalVotesSecured, 0)}</strong></td>
                          {/* <td><strong>{toWords(reportData.data.candidates.reduce((acc, candidate) => acc + candidate.totalVotesSecured, 0))}</strong></td> */}
                          <td></td>
                        </tr>
                        <tr>
                          <td colSpan="3" className="text-right"><strong>Total Rejected Votes</strong></td>
                          <td colSpan="3"><strong>{reportData.data.totalWrongVotes}</strong></td>
                        </tr>
                        <tr>
                          <td colSpan="3" className="text-right"><strong>Total Votes Cast</strong></td>
                          <td colSpan="3"><strong>{parseInt(reportData.data.candidates.reduce((acc, candidate) => acc + candidate.totalVotesSecured, 0)) + parseInt(reportData.data.totalWrongVotes)}</strong></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {reportData && text == "district" && (
              <div className="report-section">
                <div className="row mb-4">
                  <div className="col-md-3">
                    <div className="card text-white bg-warning mb-3">
                      <div className="card-body">
                        <h5 className="card-title">District</h5>
                        <p className="card-text">{reportData?.districtData?.name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-info mb-3">
                      <div className="card-body">
                        <h5 className="card-title">No. of Constituencies</h5>
                        <p className="card-text">{reportData?.constLength}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-success mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Total Votes Cast</h5>
                        <p className="card-text">{parseInt(reportData?.data?.totalVotesCast) + parseInt(reportData?.data?.totalWrongVotes)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-secondary mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Total Rejected Votes</h5>
                        <p className="card-text">{reportData?.data?.totalWrongVotes}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Candidates Table */}
                <div className="card mb-4 shadow">
                  <div className="card-body">
                    <h5 className="card-title">Constituency and Party Votes ({type})</h5>
                    <table className="table table-striped table-bordered">
                      <thead className="thead-dark">
                        <tr>
                          <th>Constituency Name</th>
                          {uniqueParties?.map((partyName) => (
                            <th key={partyName}>{partyName}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {reportData?.data?.constituencies?.map((constituency) => (
                          <tr key={constituency.constituencyName}>
                            <td>{constituency.constituencyName}</td>
                            {uniqueParties?.map((partyName) => {
                              const party = constituency.parties.find((p) => p.partyName === partyName);
                              return (
                                <td key={partyName}>
                                  votes:{party ? party.totalVotesSecured : 0}  - ({party ? party.votePercentage : 0})
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {reportData && text == "region" && (
              <div className="report-section">
                <div className="row mb-4">
                  <div className="col-md-3">
                    <div className="card text-white bg-warning mb-3">
                      <div className="card-body">
                        <h5 className="card-title">No. of Districts</h5>
                        <p className="card-text">{reportData?.districts}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-info mb-3">
                      <div className="card-body">
                        <h5 className="card-title">No. of P.S</h5>
                        <p className="card-text">{reportData?.stationResults?.totalPollingStations}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-success mb-3">
                      <div className="card-body">
                        <h5 className="card-title">P.S with result</h5>
                        <p className="card-text">{reportData?.stationResults?.pollingStationsWithResults}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-secondary mb-3">
                      <div className="card-body">
                        <h5 className="card-title">P.S with no result</h5>
                        <p className="card-text">{reportData?.stationResults?.pollingStationsWithoutResults}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-warning mb-3">
                      <div className="card-body">
                        <h5 className="card-title">P.S result %</h5>
                        <p className="card-text">{reportData?.stationResults?.pollingStationsWithResultsPercentage}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-info mb-3">
                      <div className="card-body">
                        <h5 className="card-title">No. of Constituencies</h5>
                        <p className="card-text">{reportData?.constLength}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-success mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Total Votes Cast</h5>
                        <p className="card-text">{parseInt(reportData?.data?.totalVotesCast) + parseInt(reportData?.data?.totalWrongVotes)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-secondary mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Total Rejected Votes</h5>
                        <p className="card-text">{reportData?.data?.totalWrongVotes}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Candidates Table */}
                <div className="card mb-4 shadow">
                  <div className="card-body">
                    <h5 className="card-title">Constituency and Party Votes ({type})</h5>
                    <table className="table table-striped table-bordered">
                      <thead className="thead-dark">
                        <tr>
                          <th>Constituency Name</th>
                          {uniqueParties?.map((partyName) => (
                            <th key={partyName}>{partyName}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {reportData?.data?.constituencies?.map((constituency) => (
                          <tr key={constituency.constituencyName}>
                            <td>{constituency.constituencyName}</td>
                            {uniqueParties?.map((partyName) => {
                              const party = constituency.parties.find((p) => p.partyName === partyName);
                              return (
                                <td key={partyName}>
                                  votes:{party ? party.totalVotesSecured : 0}  - ({party ? party.votePercentage : 0})
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            <div className="card-body bg-white shadow my-3">
              {chartList?.length > 0 && (
                <Chart
                  options={chartOptions}
                  series={chartOptions.series}
                  type="bar"
                  height={320}
                />
              )}
            </div>
            <div className="card-body bg-white shadow">
              {list?.length > 0 && (
                <Chart
                  options={ConstchartOptions}
                  series={ConstchartOptions.series}
                  type="bar"
                  height={320}
                />
              )}
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default ControllerResultDisplay;
